import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-base-filter',
    templateUrl: './base-filter.component.html'
})
export class BaseFilterComponent {

    @Input() btnAddNewTitle: string;
    @Input() btnExportTitle: string;
    @Input() btnImportTitle: string;
    @Input() disableAddNew = false;
    @Input() disableImportExport = true;
    @Output() clickCreate = new EventEmitter();
    @Output() clickBack = new EventEmitter();
    @Output() clickExport = new EventEmitter();
    @Output() clickImport = new EventEmitter();


    onCreate(): void {
        this.clickCreate.emit();
    }

    onExport(): void {
        this.clickExport.emit();
    }

    onImport(): void {
        this.clickImport.emit();
    }

    onBack(): void {
        this.clickBack.emit();
    }

}

<div *ngIf="loading" class="container-fluid">
  <app-loader type="list"></app-loader>
</div>
<div [hidden]="loading" class="container-fluid page-wrapper main-wrapper mt-5">
      <div class="well jsonviewer">
        <formio class="formio" #formio [form]="form"
                [language]="language"
                [submission]="submission" 
                (submit)="submition($event)" 
                (saveState)="save($event)" 
                (validate)="validate($event)" 
                (reset)="reset($event)"
                [readOnly]="readOnly"
                [options]="options"
                (customEvent)="customEvent($event)"
                (ready)="ready()"
                (render) = "render()"
                [refresh]="refreshForm"
                ></formio>
    </div>  
</div>
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewEncapsulation } from '@angular/core';
import { Classifier } from '../../../models/classifier.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None // we need to apply select.component.scss styles for ngx-select-dropdown component which is in node_modules dir therefore style encapsulation must be none
})
export class SelectComponent implements OnChanges {

  @Output() selectChange = new EventEmitter();

  @Input() label: string;
  @Input() parentPropertyName: string;
  @Input() list: Classifier[];
  @Input() selectedId: string;
  @Input() hideLabel: boolean = false;
  @Input() placeholder: string;
  
  selectedObject: Classifier;

  SELECT_ITEM_THRESHOLD = 5;

  config = {
    displayKey: 'label',               //if objects array passed which key to be displayed defaults to description
    search: false,                     // default is off
    height: '350px',                   //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: ' ',                 // text to be displayed when no item is selected defaults to Select,
    limitTo: 0,                       // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more',                 // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search...',    // label thats displayed in search input,
    searchOnKey: 'label',             // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: true,           // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr',          // the direction of the search input can be rtl or ltr(default)
    // customComparator: ()=>{}       // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
  };

  ngOnInit(){
    if (this.placeholder){
      this.config.placeholder = this.placeholder;
    }
  }

  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
    if (changes.selectedId && this.list) {
      const object = this.list.filter(f => f.value === this.selectedId)[0];
      this.selectedObject = object;
    }

    this.config.search = changes.list?.currentValue?.length > this.SELECT_ITEM_THRESHOLD ? true : false;
  }

  selectionChanged(): void {
    const value = this.selectedObject.value;

    const selectData = { 
      propertyName: this.parentPropertyName, 
      propertyValue: value 
    };

    this.selectChange.emit(selectData);
  }

}

import { Component, Inject, OnInit, ViewChild, ElementRef, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum'
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { LocalStorageService } from 'projects/urp/src/app/services/local-storage.service/local-storage.service';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { Subscription } from 'rxjs';
import { FormioComponent } from '@formio/angular';
import { TranslateService } from '@ngx-translate/core';
import { ObjectHelpers } from 'projects/core/src/lib/shared/helpers/object-helpers';

@Component({
  selector: 'app-classifier-list',
  templateUrl: './classifier-list.component.html'
})
export class ClassifierListComponent
  extends BaseListComponent
  implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  actions: string[] = ['view', 'edit'];
  searchData = new DefaultParams('NaturalKey asc');
  defaultSearchData = new DefaultParams('RowOrder asc');

  listUrl = `${routesEnum.domains.url}/${routesEnum.classifiers.url}`;
  formEnum = formEnum.classifierForm;
  form: any;
  listingFormJson: any;
  loading = true;
  formOptions: any;
  formSubmission: any = {};
  query: any = {};

  tableColumns = [
    new TableColumn('NaturalKey', 'pages.classifier.list.data.code'),
    new TableColumn('Name', 'pages.classifier.list.data.name', 'notTranslatable'),
    new TableColumn('Name', 'pages.classifier.list.data.translatedName'),
    new TableColumn('ValidFrom', 'pages.classifier.list.data.validFrom', 'date'),
    new TableColumn('ValidTo', 'pages.classifier.list.data.validTo', 'date'),
    new TableColumn(null, null, 'action')
  ];

  @Input() filterHeader: string;

  language = new EventEmitter<string>();
  addTranslations = new EventEmitter<any>();
  refreshForm = new EventEmitter();
  languageSubscription: Subscription;
  @ViewChild('formio') formioComponent: FormioComponent;
  cachedLanguages = {};

  listFormId: string;

  formId = null;

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected readonly keycloak: KeycloakService,
    protected httpService: HttpService,
    protected location: Location,
    protected route: ActivatedRoute,
    private helper: HelperService,
    protected storage: LocalStorageService,
    protected formsService: FormsService,
    protected translateService: TranslateService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, route, keycloak, location, httpService);
    this.apiUrl = this.environment.api.classifier;

    this.form = { display: 'form', components: [] };
    this.getOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.defaultSearchData["ClassifierDomainNaturalIds"] = this.searchData.ClassifierDomainNaturalIds;
    this.getParentInformation(`${this.environment.api.domain}/GetByNaturalKey/${this.searchData.ClassifierDomainNaturalIds}`);

    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      if (this.formioComponent)
        this.addLanguage(this.getCurrentLanguage());
    });

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.query = queryParams;
    });

    this.loadData();

  }

  protected beforeLoadList(): void {
    super.beforeLoadList();

    this.createQueryParams = {
      FormCode: formEnum.classifierForm,
      ClassifierDomainNaturalKey: this.searchData.ClassifierDomainNaturalIds
    };

    this.searchData["translationLanguage"] = this.helper.getCurrentSystemLanguage();
  }

  protected override onCreate(): void {
    const formCode = this.parentInfo.ValueFormCode || routesEnum.form.url;
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: { FormCode: formCode ?? this.formEnum, ClassifierDomainNaturalKey: this.searchData.ClassifierDomainNaturalIds } });
  }

  protected override onEdit(item: any): void {
    const formCode = this.parentInfo.ValueFormCode || routesEnum.form.url;
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: { FormCode: formCode ?? this.formEnum, Id: item.Id, ClassifierDomainNaturalKey: this.searchData.ClassifierDomainNaturalIds } });
  }

  protected override onView(item: any): void {
    const formCode = this.parentInfo.ValueFormCode || routesEnum.form.url;
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: { FormCode: formCode ?? this.formEnum, Id: item.Id, readOnly: true } });
  }

  private onImport(): void {
    this.fileInput.nativeElement.click();
  }

  private onExport(): void {
    const tempSearchData = { ...{}, ...this.searchData };
    delete tempSearchData.SortBy;
    delete tempSearchData.PageSize;
    delete tempSearchData.Page;

    const param = this.httpService.getQueryParams(tempSearchData);
    const url = `${this.environment.api.classifier}/export?${param}`;

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.get(url, httpOptions).subscribe(res => {
      const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "classifiers.json";
      link.click();
      link.remove();

      this.classifiersLoading = false;
    },
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'application/json') {
        this.uploadFile(file);
      } else {
        this.toastrService.serverError('Only JSON files are allowed.');
      }
    }
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.post(`${this.environment.api.classifier}/import`, formData, httpOptions).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = "classifiers.json";
        link.click();
        link.remove();

        this.classifiersLoading = false;
        this.fileInput.nativeElement.value = "";
      },
      (err) => {
        this.toastrService.serverError(err);
        this.fileInput.nativeElement.value = "";
      }
    );
  }

  protected loadData() {

    this.formsService.getFormById(this.environment.formIds.classifierListFormId).subscribe(res => {
      let response: any = res;
      this.form.components = response.components;
      this.searchData = Object.assign({}, this.defaultSearchData);
      this.refreshForm.emit({
        form: this.form
      });
      super.ngOnInit();

     this.loadList();
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  addLanguage(lang: string): void {
    if (!this.cachedLanguages[lang.toLowerCase()]) {
      this.formioComponent.formio.addLanguage(lang.toLowerCase(), this.storage.retrieve(`translations_${lang}`));
      this.cachedLanguages[lang.toLowerCase()] = true;
    }
    this.language.emit(lang.toLowerCase());
  }

  getOptions(): void {
    const lang = this.getCurrentLanguage();
    this.formOptions = {
      template: 'bootstrap5',
      disableAlerts: true,
      noAlerts: true,
      language: lang.toLowerCase(),
      i18n: {
        [lang.toLowerCase()]: this.storage.retrieve(`translations_${lang}`)
      }
    }
    this.cachedLanguages[lang.toLowerCase()] = true;
  }

  public formioCustomEvent(event: any): void {
    const mergedObject = {
      ...this.searchData,
      ...event.data
    };
    switch (event.type) {
      case 'filter':
        this.searchData = mergedObject;
        this.onFilter();
        break;
      case 'clear':
        this.clearFilter();
        break;
      default:
        alert(`Event ${event.type} is not handled for this form yet.`);
    }
  }

  public formioChange(event: any): void {

    if (JSON.stringify(event.metadata) == JSON.stringify({})) {
      for (const key of this.query.keys ?? []) {
        const values = this.query.getAll(key);
        if (values.length === 1) {
          this.searchData[key] = values.shift();
        }
        else {
          this.searchData[key] = values;
        }
      }

      this.refreshForm.emit({
        submission: {
          data: {
            filter: ObjectHelpers.unflatten(this.searchData)
          }
        }
      });
    }

    if (event.data?.filter) {
      const mergedObject = {
        ...this.searchData,
        ...ObjectHelpers.flatten(event.data.filter)
      };
      this.searchData = mergedObject;
    }
  }

  render(): void {
    const lang = this.getCurrentLanguage();
    this.language.emit(lang.toLowerCase());
  }

  protected override clearFilter(): void {
    this.searchData = Object.assign({}, this.defaultSearchData);
    this.refreshForm.emit({
      submission: {
        data: {
          filter: ObjectHelpers.unflatten(this.searchData)
        }
      }
    });
    this.loadList();
  }
}

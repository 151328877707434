<div >        
    <div class="accordion-body">
        <h1>{{'pages.messageTemplate.form.title' | translate}}</h1>
        <div class="form-holder">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="form-group">
                        <label class="label--dark" for="Code">{{'pages.messageTemplate.form.code'
                            | translate}}:</label>
                        <div class="form-element">
                            <input id="Code" class="input" type="text" readonly="true"
                                [(ngModel)]="model.Code" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                    <div class="form-group">
                        <label class="label--dark" for="Description">{{'pages.messageTemplate.form.description'
                            | translate}}:</label>
                            <div class="form-element">
                                <textarea id="Description" class="input" type="text" readonly="true"
                                [(ngModel)]="model.Description"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                    <mat-tab-group animationDuration="0ms">
                        <template *ngFor="let subTemplate of subTemplates; let i = index">
                            <mat-tab  label="{{subTemplate.LanguageCode}}" style="position: relative;"> 

                                <div class="accordion-body" >
                                    <div class="row">
                                            <div class="form-group w-100">
                                                <label class="label--dark" for="Title">{{'pages.messageTemplate.form.messageTitle'
                                                    | translate}}:</label>
                                                <div class="form-element">
                                                    <input id="Code" class="input" type="text" readonly="true"
                                                        [(ngModel)]="subTemplate.Title" />
                                                </div>
                                            </div>
                                            <div class="form-group w-100">
                                                <label class="label--dark" for="Title">{{'pages.messageTemplate.form.messageHtmlBody'
                                                    | translate}}:</label>
                                                <div class="form-element">
                                                    <textarea id="Code" class="input" type="text" readonly="true"
                                                        [(ngModel)]="subTemplate.BodyTemplate" ></textarea>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </template>
                      </mat-tab-group>
                </div>
            </div>
    </div>
</div>
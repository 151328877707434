<div *ngIf="isLoggedIn">
    <app-user-session-timeout></app-user-session-timeout>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-stretch" userActivityTacker>
        <div [ngClass]="loading ? 'overlay-spinner' : ''"></div>
        <app-sidebar></app-sidebar>
        <app-sidebar-second></app-sidebar-second>
        <div class="w-100 d-flex flex-column" >
            <app-header></app-header>
            <div class="scroll-bar gx-0" [ngClass]="this.environment.misc.additionalBackgroundClass">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isLoggedIn">
    <app-header-public></app-header-public>
    <router-outlet></router-outlet>
</div>
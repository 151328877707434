export class FilterUserParams {
    Status: number;
    Page: number;
    PageSize: number;
    SortBy: string;
    constructor() {
        this.Status = 0;
        this.Page = 1;
        this.PageSize = 10;
        this.SortBy = 'FullName asc';

    }
}

export class DefaultParams {
    Page: number;
    PageSize: number;
    SortBy: string;
    ClassifierDomainNaturalIds: string;
    constructor(sortBy: string, pageSize = 10) {
        this.Page = 1;
        this.PageSize = pageSize;
        this.SortBy = sortBy;
    }
}


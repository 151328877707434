<button *ngIf="debug" type="button" class="btn btn-primary" (click)="showModal()">Open a modal</button>
<div class="modal fade" bsModal #modal="bs-modal"
     tabindex="-1" role="dialog" aria-hidden="true"
     (onShow) = "showAnimationStart()" (onHide) = "hideAnimationStart()"
     (onShown)="showAnimationFinished()" (onHidden)="hideAnimationFinished()">
  <div class="modal-dialog modal-dialog-centered" [ngClass]='modalClass'>
    <div class="modal-content">
      <div class="modal-error">
        <ng-content select="[error]"></ng-content>
      </div>
      <div class="modal-header">
        <div class="start" tabindex="0" (keydown)="keyAction($event, 'start')"></div>
        <h1 class="modal-title">
          <ng-content select="[header]"></ng-content>
        </h1>
        <button *ngIf="showExitButton" type="button" class="close" aria-label="Close" title="Close" (click)="exitButtonClick()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </div>
      <div tabindex="0" (keyup)="keyAction($event, 'end')"></div>
    </div>
  </div>
</div>
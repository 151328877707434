<div class="header-notification " style="margin-left: 1rem; margin-right: 1rem;">
    <span class="bold center" (click)="showNotificationList()" >
        <div *ngIf="!anyUnreadNotifications" class="urp-icon urp-icon-inbox"></div>
        <div *ngIf="anyUnreadNotifications" 
            matBadge="&#;"
            matBadgeColor="warn"
            matBadgeSize="small"
            class="urp-icon urp-icon-inbox"></div>
    </span>
</div>

/**
 * This file shows how to create a custom component and register that within an Angular application.
 *
 * Get the base component class by referencing Formio.Components.components map.
 */
import { Components } from "@formio/angular";
const DateTime = Components.components.datetime;

export default class DateTimeExtend extends DateTime {
  constructor(component, options, data) {
    options.language = "en";
    super(component, options, data);
    this.on('render', () => {
      if (!this.options.readOnly && window.location.href.includes('/form')) {
        if (this.getValue()) { 
          this.dataValue  = this.getValue().substring(0, 19) + "Z";
        }
      }
    });
  }

  get emptyValue() {
    return null;
  }

  getValue() {
    let value = super.getValue();
    return value ? value : null;
  }

  get dataValue() {
    const parent = super.dataValue;    
    return parent;
  }

  set dataValue(value) {
    if (
      !this.allowData ||
      !this.key ||
      (!this.visible && this.component.clearOnHide && !this.rootPristine)
    ) {
      return;
    }
    if ((value !== null) && (value !== undefined)) {
      value = this.hook('setDataValue', value, this.key, this._data);
    }
    if ((value === null) || (value === undefined)) {
      _.set(this._data, this.key, value);
      return;
    }
    _.set(this._data, this.key, value);
    return;
  }


}

// Register the component to the Formio.Components registry.
Components.addComponent("datetime", DateTimeExtend);

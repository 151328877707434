<div class="modal-header bg-white">
    <h5 translate class="modal-title" id="exampleModalLabel">
        <span *ngIf="isDeletion" class="me-2 mt-2 urp-icon urp-icon-delete" 
            [class.icon-delete]="isDeletion"
            [class.icon-lock]="isConfirmation"
            [class.icon-view]="!isDeletion && !isConfirmation">
        </span>{{title}} 
    </h5>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="decline()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body form-holder bg-white">
    <div class="form-group bg-white">
        <div class="form-element bg-white">
            <label *ngIf="showCheckbox; else elseBlock" translate for="isChecked" class="label--checkmark text-dark">
                {{message}}
            <span>
                <input id="isChecked" class="checkbox" type="checkbox" required="showCheckbox" [checked]="isChecked" (change)="isChecked = !isChecked" />
                <span class="checkmark"></span>
            </span>
            </label>
            <ng-template #elseBlock>
            <label translate>
                {{message}}
            </label>
        </ng-template>
        </div>
    </div> 
</div>
<div class="modal-footer bg-white">
    <button *ngIf="isDeletion || isConfirmation" type="button" class="btn btn-outline-primary btn-block w-100" (click)="decline()" translate>{{cancelButtonName}}</button>
    <button type="button" class="btn btn-primary btn-block w-100" (click)="confirm()" [disabled]="!isChecked && showCheckbox" translate>{{okButtonName}}</button>   
</div>

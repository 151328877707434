<div class="d-flex pb-3">
    <h3 class="me-auto" translate>{{title}}</h3>
    <div class="form-group">
      <a translate class="cp-text-primary cp-font-weight-600 clear-button"
        (click)="onClearFilter()">pages.common.list.clearFilterButton</a>
        <a translate class="btn btn-outline-primary close-filter ms-3 cp-font-weight-600"
        (click)="onCloseFilter()">pages.common.list.closeFilterButton
        <i class="fa-solid fa-xmark"></i>
        </a>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { routesEnum } from '../../../enumerators/routesEnum';
import { DefaultParams } from '../../../models/filter.model';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { formEnum } from '../../../enumerators/Forms.enum';
import { TableColumn } from '../../../models/table-column.model';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { HttpService } from '../../../services/http-service/http.service';
import { Location } from '@angular/common';
import { Formio } from 'formiojs';
import { HelperService } from '../../../services/helper.service/helper.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';

@Component({
  selector: 'app-registration-object-history-list',
  templateUrl: './registration-object-history-list.component.html'
})
export class RegistrationObjectHistoryListComponent extends BaseListComponent {

    searchData = new DefaultParams('LastUpdateDate desc');
    defaultSearchData = new DefaultParams('LastUpdateDate desc');
 
    apiUrl = this.environment.api.registerHistory;
    listUrl = routesEnum.registerHistoryList.url;
    formEnum = formEnum.rorApplicationForm;
    actions: string[] = ['view'];

    tableColumns = [
        new TableColumn('LastUpdateDate', 'pages.regHistory.data.creationDate', 'datetime'),
        new TableColumn('RegisterServiceName', 'pages.regHistory.data.orderedEServiceName'),
        new TableColumn('TrackingNumber', 'pages.regHistory.data.trackingNo'),
        new TableColumn('ApplicantFullName', 'pages.regHistory.data.applicantFullName'),
        new TableColumn('StatusNaturalKey', 'pages.regHistory.data.regObjectStatus', 'cls', "CLS.REGISTER-RECORD-STATUS"),
        new TableColumn(null, null, 'action')
    ];

    constructor(protected toastrService: ToastrServiceExt,
        protected modal: BsModalService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected readonly keycloak: KeycloakService,
        protected location: Location,
        protected httpService: HttpService,
        private helper: HelperService,
        @Inject(APP_CONFIG) private readonly environment: IConfig) {
        super(toastrService, modal, router, activatedRoute, keycloak, location, httpService);
    }

    protected override loadClassifiers(): void {
        this.httpService.get(`${this.environment.api.classifier}/search?ClassifierDomainNaturalIds=REGISTER-RECORD-STATUS`).subscribe(res => {
            this.classifierData.regObjectStatuses = res;
            this.classifiersLoading = false;
            this.loadList();
        }, (err) => {
            this.toastrService.serverError(err);
        });
    }

    protected override async onView(item: any): Promise<void> {
        void this.router.navigate([routesEnum.form.url], {  relativeTo: this.activatedRoute, queryParams: this.createQueryParams ?? { FormCode: item.FormCode ?? this.formEnum, RegisterId: this.searchData['RegisterId'], Id: item.Id, readOnly: true } });
    }

    protected override clearFilter(): void {
        this.searchData = Object.assign({}, this.defaultSearchData, {  relativeTo: this.activatedRoute, RegisterId: this.searchData['RegisterId'], RegistrationNumberExact: this.searchData['RegistrationNumberExact'] });
        this.loadList();
      }
}

import { Component, Inject, OnDestroy } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HelperService } from '../../../services/helper.service/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-viewer',
  templateUrl: './notification-viewer.component.html',
  styleUrls: ['./notification-viewer.component.scss']
})
export class NotificationViewerComponent implements OnDestroy {

  notification;
  notificationBody: SafeHtml;
  notificationDate: string;

  searchData;
  initSearchData;

  subscriptions: Subscription[] = [];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              protected translateService: TranslateService,
              private sanitizer: DomSanitizer,
              private helper: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
              
  }

  ngOnInit(): void {
    this.getQueryParams();

    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => {
      this.loadLanguage();
      this.loadNotification();
    }));

    this.loadNotification();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  markAsRead(id) : void {
    let guids = [];
    guids.push(id);

    this.httpService.post(`${this.environment.api.notifications}/markRead`, guids).subscribe(res => {
    }, (err) => {
        this.toastrService.serverError(err);
    });
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  loadNotification(): void {
    if (this.searchData.Id) {
      this.markAsRead(this.searchData.Id);
      this.httpService.get(`${this.environment.api.notifications}/${this.searchData.Id}/${this.searchData.LanguageId}`).subscribe(res => {
        this.notification = res;
        this.notificationBody = this.sanitizer.bypassSecurityTrustHtml(this.notification.Body);
        const date = new Date(this.notification.FirstNotificationReadDateTime);
        this.notificationDate = date.toLocaleString(this.getCurrentLanguage());
      }, (err) => {
        this.toastrService.serverError(err);
      });
    }
  }

  getQueryParams(): void {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {

      this.searchData = Object.assign({}, this.initSearchData);

      for (const key of queryParams.keys) {
        const values = queryParams.getAll(key);
          if (values.length === 1) {
            this.searchData[key] = values.shift();
          }
          else {
            this.searchData[key] = values;
          }    
      }
    });
  }

  loadLanguage(): void {
    this.searchData.LanguageId = this.helper.getCurrentSystemLanguageId();
    this.setQueryParams();
  }

  setQueryParams(): void {
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.searchData,
    });
  }  
}


<!-- loader for lists -->
<div *ngIf="type === 'list'">
    <app-list-skeleton></app-list-skeleton>
</div>

<!-- default loader -->
<div *ngIf="!type" class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <div class="d-flex justify-content-center">
                <ngx-skeleton-loader count="5" appearance="circle"></ngx-skeleton-loader>
            </div>
        </div>
    </div> 
</div>
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { KeycloakService } from 'keycloak-angular';

/**
 * This interceptor includes the IdToken by default in all HttpClient requests.
 *
 * If you need to exclude some URLs from adding the IdToken, please, take a look
 * at the {@link KeycloakOptions} bearerExcludedUrls property.
 */
@Injectable()
export class KeycloakIdTokenInterceptor implements HttpInterceptor {
    constructor(private keycloak: KeycloakService) {}

    /**
     * Checks if the url is excluded from having the Bearer Authorization
     * header added.
     * excluded from adding the IdToken at the Http Request.
     */
    private isUrlExcluded({ method, url }: HttpRequest<unknown>, { urlPattern, httpMethods } : any): boolean {
      const httpTest = httpMethods.length === 0 || httpMethods.join().indexOf(method.toUpperCase()) > -1;

      const urlTest : boolean = urlPattern.test(url);

      return httpTest && urlTest;
    }

    /**
    * Intercept implementation that checks if the request url matches the excludedUrls.
    * If not, adds the Authorization header to the request if the user is logged in.
    *
    * @param request
    * @param next Next Angular HTTP interceptor/handler
    */
    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const { enableBearerInterceptor, excludedUrls } = this.keycloak;
        if (!enableBearerInterceptor) {
          return next.handle(request);
        }
        const shallPass : boolean = excludedUrls.findIndex((item) => this.isUrlExcluded(request, item)) > -1;
        if (shallPass) {
          return next.handle(request);
        }

        return from(this.keycloak.isLoggedIn()).pipe(
          mergeMap((loggedIn: boolean) =>
            loggedIn ? this.handleRequestWithTokenHeader(request, next) : next.handle(request)
          )
        ) as Observable<HttpEvent<unknown>>;
    }

  /**
   * Adds the ID token of the current user to the IdToken header
   *
   * @param request
   * @param next Next Angular HTTP interceptor/handler
   */
    private handleRequestWithTokenHeader(request: HttpRequest<unknown>, next: HttpHandler): Observable<unknown> {
        const idToken = this.keycloak.getKeycloakInstance().idToken;
        const idTokenReq = request.clone({
          headers: request.headers.set('IdToken', idToken)
        });
        return next.handle(idTokenReq);
    }
}
<app-breadcrumb [isHeader]="true"></app-breadcrumb>
<div class="container-fluid c-max-width-755px">
    <div class="w-100 d-flex my-4 cp-ps-32">
        <button class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0"
            [routerLink]="'/myOffice'">
            <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
        </button>
        <h4 class="my-0 mx-3" translate>pages.myOffice.MyRegisterRecords</h4>
    </div>
    <div *ngIf="MyOfficeRegisterListForm" class="container-fluid c-max-width-755px">
        <app-dynamic-list [module]="module" [listingMongoId]="MyOfficeRegisterListForm" [showFilterButton]="false"
            [staticFilter]="true" [autoRefreshList]="true">
        </app-dynamic-list>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { DefaultParams } from 'projects/urp/src/app/models/filter.model';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-representative-list',
  templateUrl: './representative-list.component.html',
  styleUrls: ['./representative-list.component.scss']
})
export class RepresentativeListComponent extends BaseListComponent implements OnInit {
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');

  listUrl = routesEnum.representativeList.url;
  formEnum = formEnum.internalRepresentativeForm;

  tableColumns = [
    new TableColumn('AccountName', 'pages.representative.list.data.accountName'),
    new TableColumn('AccountIdentifier', 'pages.representative.list.data.accountIdentifier'),
    new TableColumn('Username', 'pages.representative.list.data.username'),
    new TableColumn('UserFullName', 'pages.representative.list.data.userFullName'),
    new TableColumn('ValidFrom', 'pages.representative.list.data.validFrom', 'datetime'),
    new TableColumn('ValidTo', 'pages.representative.list.data.validTo', 'datetime'),
    new TableColumn(null, null, 'action')
  ];

  actions: string[] = ['view', 'edit'];

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected readonly keycloak: KeycloakService,
    protected httpService: HttpService,
    protected location: Location,
    protected activatedroute: ActivatedRoute,
    protected helperService: HelperService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedroute, keycloak, location, httpService);
    this.apiUrl = this.environment.api.umRepresentatives;    
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }
}

import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RegisterServiceDTO } from '../../register/services-list/register-services-list.component';
import { HttpService } from '../../../services/http-service/http.service';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { IConfig } from '../../../models/config.interface';
import { APP_CONFIG } from '../../../utility/tokens';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
})
export class ThumbnailComponent implements OnInit {

  @Input() buttonText: string;
  @Input() register: RegisterServiceDTO; 
  @Input() tooltipText: string;

  @Output() $onClick: EventEmitter<any> = new EventEmitter(); 

  @ViewChild('thumbnail', {static: false}) thumbnail: ElementRef;

  registerName: string;
  isLoading: boolean = true;
  apiUrl: string;
  faIcon: string = 'far fa-question-circle thumbnail-question'; 

  constructor(private httpService: HttpService,
    private toastrService: ToastrServiceExt,
    @Inject(APP_CONFIG) private readonly environment: IConfig) { 
    this.apiUrl = this.environment.api.files + '/';
  }

  ngOnInit() {
    this.load();
  }

  onClick() {
    this.$onClick.emit();
  }

  load() {
    this.registerName = this.register.Name;
    this.loadThumbNail();

    this.isLoading = false;
  }

  loadThumbNail() {
    this.httpService.get(this.apiUrl + this.register.ThumbnailEntityId, {responseType: 'blob', reportProgress: false, observe: 'events'})
    .subscribe((res) => {
      this.thumbnail.nativeElement.src = URL.createObjectURL(res);
      this.faIcon = this.tooltipText ? 'far fa-question-circle thumbnail-question' : ''; 

    }, (err) => {
      this.toastrService.serverError(err);
    })
  }
}

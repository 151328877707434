import { Form } from "formiojs";
import Displays from './Displays';

export default class FormExtended extends Form {

    create(display) {
        if (this.options && (this.options.flatten || this.options.renderMode === 'flat')) {
            display = 'form';
        }
        this.display = display;
        // eslint-disable-next-line new-cap
        return new Displays.displays['WebformExtended'](this.element, this.options);
    }
}
<!-- button on upper right -->
<div class="navigation">
    <div class="navigation-right">
        <ngx-skeleton-loader  [theme]="{ width: '350px'}"></ngx-skeleton-loader>
    </div>
    <div class="clearfix"></div>
</div>

<!-- filter -->
<div class="accordion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
          <button class="accordion-button" type="button" aria-expanded="true" >
            <ngx-skeleton-loader></ngx-skeleton-loader> <a class="btn--cust btn--delete btn--thin float-end">Clear criteria</a>
          </button>
      </h2>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse show" aria-labelledby="panelsStayOpen-headingTwo">
          <div class="accordion-body">
              <div class="form-holder">
              <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="form-group">
                          <label class="label--dark" for="shortName"><ngx-skeleton-loader></ngx-skeleton-loader></label>
                          <div class="form-element">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="form-group">
                        <label class="label--dark" for="Name"><ngx-skeleton-loader></ngx-skeleton-loader></label>
                        <div class="form-element">
                          <ngx-skeleton-loader></ngx-skeleton-loader>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col">
                    <div class="form-group">
                        <div class="form-group-actions">
                          <ngx-skeleton-loader  [theme]="{ width: '350px', 'margin-right': '50px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader  [theme]="{ width: '350px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject,  ViewChild, ElementRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { DefaultParams } from 'projects/urp/src/app/models/filter.model';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';

@Component({
  selector: 'app-id-generation-config-list',
  templateUrl: './external-client-list.component.html'
})
export class ExternalClientListComponent extends BaseListComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  searchData = new DefaultParams('Code asc');
  defaultSearchData = new DefaultParams('Code asc');
  
  listUrl = routesEnum.externalClientsList.url;
  formEnum = formEnum.externalClientForm;

  tableColumns = [
    new TableColumn('ClientId', 'pages.externalKeycloakClient.list.table.clientId'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService); 
    this.apiUrl = this.environment.api.externalClients;
    this.actions = ['view', 'edit'];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }
}

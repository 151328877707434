import { Component, EventEmitter, Output } from '@angular/core';
import { MenuService } from 'projects/urp/src/app/services/menu.service/menu.service';
import { BaseMenuComponent } from '../../../shared/base-menu/base-menu';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HeaderComponent extends BaseMenuComponent {
  stateOfMenu = true;
  constructor(protected menuService: MenuService) {
    super(menuService);
  }
  ngOnInit() {
    document.getElementById("c-retractable").style.maxWidth = "300px";
    document.getElementById("c-retractable").style.minWidth = "300px";
    let i = 0;
    var targetDivsAppear = document.getElementById("c-retractable").getElementsByClassName("collapseAppear");
    while(i < targetDivsAppear.length){
      targetDivsAppear[i]['style'].display = "none";
      i++;
    }
    this.menuService.showSidebarEvent.subscribe(show => {
      this.stateOfMenu = show;
    });
  }
  onResize(event) {
    this.currentWidth(event.target.innerWidth);
    document.getElementById('myNav').classList.remove('opened-nav');
    document.getElementById('myNav').classList.add('closed-nav');
  }
  currentWidth(w) {
    if(w >= 768){
        this.stateOfMenu = !this.stateOfMenu;
        this.collapseMenu();
    }
    else {
        const tempVar = this.stateOfMenu;
        this.stateOfMenu = false;
        this.collapseMenu();
        this.stateOfMenu = tempVar;
    }
  }
  collapseMenu(){
    this.menuService.setSideBarSecondData({});
    if (!this.stateOfMenu){
        this.maxSideMenu();
    } else 
      this.minSideMenu();
  }    
}







import { NgModule } from '@angular/core';
import { Formio, Templates } from '@formio/angular';
import { PluginHooks } from './plugin-hooks/plugin-hooks';

//Components
import './components/DateTimeExtended';
import './components/FormExtended';
import './components/FileExtended';
import './components/RadioExtended';
import './components/SelectExtended';
import './components/EditGridExtended';

// Templates
import editgridExtended from './templates/editgrid';
Templates.defaultTemplates['editgrid'] = editgridExtended;

import fileExtended from './templates/file';
Templates.defaultTemplates['file'] = fileExtended;

//Plugins
Formio.registerPlugin(PluginHooks, 'PluginHooks');

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [],
    bootstrap: []
})

export class FormioExtensionsModule { }
export class MessageTemplate {
    FormId: string = "formId"; // it is necessary for exporting and importing,
    FormCode: string = "formCode"; // this is temporary until we remake the form in formio
    code: string;
    description: string;
    id: string;
    messageSubTemplates: MessageSubTemplate[]

    constructor( ) {

    }
}

export class MessageSubTemplate {
    LanguageId: string;
    Id: string;
    EmailMessageBody: string;
    RawEmailMessage: string;
    Title: string;
    LanguageCode: string;
    

    constructor() {
        this.Id = null;
        this.LanguageId = "";
        this.EmailMessageBody = "";
        this.RawEmailMessage = "";
        this.Title = "";
        this.LanguageCode = "";
    }
}

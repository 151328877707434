import { Inject, Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import * as lodash from 'node_modules/lodash'
import 'rxjs/add/operator/map'
import { LocalStorageService } from './local-storage.service/local-storage.service';
import { IConfig } from '../models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class TranslateLoaderService implements OnInit, TranslateLoader {
  isLoggedIn: boolean = false;
  
  constructor(private http: HttpClient,
    private storage: LocalStorageService,
    private keycloak: KeycloakService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    }

  public async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
  }

  getTranslation(lang: string): any {
    let url = `${this.environment.api.translation}/search?languageCode=${lang}`;
    if (!this.isLoggedIn)
      url = `${this.environment.api.pubTranslation}/search?languageCode=${lang}`;
    return this.http.get(url).map(response => {
      const obj = {};
      const translationForStorage = {};
      for (const i in response) {
        const cls = response[i];
        const kvp = { Key: cls.Code, Value: cls.Text }
        translationForStorage[cls.Code] = cls.Text;
        lodash.set(obj, kvp.Key, kvp.Value)
      }
      this.storage.store(`translations_${lang}`, translationForStorage);
      return obj;
    })
  }
}

import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum' 
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { AppConfigService } from 'projects/urp/src/app/services/app-config.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-register-service-list',
  templateUrl: './register-service-list.component.html'
})
export class RegisterServiceListComponent extends BaseListComponent  {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  
  searchData = new DefaultParams('Code asc');
  defaultSearchData = new DefaultParams('Code asc');

  listUrl = routesEnum.registerServiceList.url;
  formEnum = formEnum.registerServiceGeneralForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Code', 'pages.registerService.form.code'),
    new TableColumn('Name', 'pages.registerService.form.name'),
    new TableColumn('Register', 'pages.registerService.form.register'),
    new TableColumn('From', 'pages.registerService.form.validFrom'),
    new TableColumn('To', 'pages.registerService.form.validTo'),    
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected location: Location,
              protected httpSerice: HttpService,
              private appConfigService: AppConfigService,
              protected httpService: HttpService,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) { 
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpSerice); 
    this.apiUrl = this.appConfigService.getConfig().api.service;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.RegisterService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }

  private onImport(): void{
    this.fileInput.nativeElement.click();
  }

  private onExport(): void {
    const tempSearchData = { ...{}, ...this.searchData };
    delete tempSearchData.SortBy;
    delete tempSearchData.PageSize;
    delete tempSearchData.Page;

    const param = this.httpService.getQueryParams(tempSearchData);
    const url = `${this.environment.api.service}/export?${param}`;

    const httpOptions = {
      responseType: 'blob'
    };

    this.httpService.get(url, httpOptions).subscribe(res => {
      const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "RegisterService.zip";
      link.click();
      link.remove();

      this.classifiersLoading = false;
    },
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'application/x-zip-compressed') {
        this.uploadFile(file);
      } else {
        this.toastrService.serverError('Only zip files are allowed.');
      }
    }
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.post(`${this.environment.api.service}/import`, formData, httpOptions).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = "registerServiceImportResult.json";
        link.click();
        link.remove();

        this.classifiersLoading = false;
        this.fileInput.nativeElement.value = "";
      },
      (err) => {
        this.toastrService.serverError(err);
        this.fileInput.nativeElement.value = "";
      }
    );
  }

  protected override onEdit(item: any): void {
    void this.router.navigate([`${routesEnum.registerService.url}`], { queryParams: { 
      FormCode: formEnum.registerServiceGeneralForm,
      RegisterServiceId: item.Id, 
      RegisterServiceConfigurationId: item.RegisterServiceConfigurationId,
      RegisterServiceWorkflowConfigurationId: item.RegisterServiceWorkflowConfigurationId,
      RegisterServiceRegisterObjectLoadCleanUpConfigurationId: item.RegisterServiceRegisterObjectLoadCleanUpConfigurationId} 
    }); 
  }

  protected override onView(item: any): void {
    void this.router.navigate([`${routesEnum.registerService.url}`], { queryParams: { FormCode: formEnum.registerServiceGeneralForm, Id: item.Id, readOnly: true } }); 
  }

  protected onCreate(): void {
    void this.router.navigate([`${routesEnum.registerService.url}`], { queryParams: { FormCode: formEnum.registerServiceGeneralForm } }); 
  }
}
 
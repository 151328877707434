<div *ngIf="loading" class="container-fluid">
  <app-loader type="list" ></app-loader>
</div>

<div *ngIf="!loading">
  <app-breadcrumb [isHeader]="true"></app-breadcrumb>
  <div class="bg-secondary px-3">
    <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
      <app-filter-buttons [title]="'pages.accountType.list.data.accountTypetitle'" (clickClearFilter)="clearFilter()" (clickCloseFilter)="closeFilter()">
      </app-filter-buttons>
    </div>
    <div [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
      <div class="justify-content-center">
        <formio class="formio" #formio [form]="form" [language]="language" [submission]="formSubmission"
              [options]="formOptions" (customEvent)="formioCustomEvent($event)" (change)="formioChange($event)"
              [refresh]="refreshForm" (render)="render()">
        </formio>
      </div>
    </div>
    <div class="form-group pb-3" [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
      <a translate class="btn btn-primary cp-font-weight-700"
        (click)="onFilter()">pages.common.list.searchButton
      </a>
    </div>
  </div>

  <ng-container>
    <div class="table-holder">
      <div class="grid-classifier-header">
        <div>    
          <h3 class="me-auto" translate>sidemenu.accountType</h3>
        </div>
        <div *ngIf="isFilterClosed">
            <a class="btn btn-outline-primary open-filter-margin btn-right float-end" (click)="openFilter()" translate>pages.common.list.filterButton
                <i class="fa-solid fa-list"></i>
            </a>
        </div>
    </div>
    <app-base-filter [disableImportExport]=true
      [disableAddNew]="disableAddNew"
      [btnAddNewTitle]="'pages.accountType.list.addNewButton'"
      (clickCreate)="onCreate()" 
      (clickBack)="onBack()">
    </app-base-filter>
    <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" 
    [tableColumns]="tableColumns" 
    [listActions]="actions"
    [classifiers]="classifierData"
    (edit)="onEdit($event)" 
    (view)="onView($event)"
    (delete)="onDelete($event)"  
    (toList)="onToList($event)" 
    (sortChange)="onSortChange($event)">
  </app-data-table>
    </div>
    <app-pagging [page]="searchData.Page"
    [pageSize]="searchData.PageSize"
    [total]="totalItem"
    (pageSizeChange)="onPageSizeChange($event)"
    (pageChange)="onPageChange($event)" ></app-pagging>
  </ng-container>
</div>

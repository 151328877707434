export const html = function (ctx) {
    var str = '';
    str += `<ul class="editgrid-listgroup list-group
      ${ctx.component.striped ? 'table-striped' : ''}
      ${ctx.component.bordered ? 'table-bordered' : ''}
      ${ctx.component.hover ? 'table-hover' : ''}
      ${ctx.component.condensed ? 'table-sm' : ''}
      ">
    ${ctx.header ?
        `<li class="list-group-item list-group-header green">
      ${ctx.header}
    </li>` : ''}`;
  
    if (ctx.rows) {
      ctx.rows.forEach(function (row, rowIndex) {
        str += `<li class="list-group-item green" ref="${ctx.ref.row}">
      ${row}
      ${(ctx.openRows[rowIndex]) ? `<div class="editgrid-actions">
      ${!ctx.readOnly && !ctx.disabled && !ctx.instance.disabledRows.includes(rowIndex) ?
              `<button class="btn btn-primary" ref="${ctx.ref.saveRow}">${ctx.t(ctx.component.saveRow || 'Save', { _userInput: true })}</button>` : ''}
        <button class="btn btn-outline-primary" ref="${ctx.ref.cancelRow}">${ctx.t(ctx.component.removeRow || 'Cancel', { _userInput: true })}</button>
      </div>` : ''}
      ${ctx.errors[rowIndex] ?
            `<div class="has-error">
        <div class="editgrid-row-error help-block">
          ${ctx.errors[rowIndex]}
        </div>
      </div>
    </li>` : ''}
    ${(ctx.footer) ?
            `<li class="list-group-item list-group-footer">
      ${ctx.footer}
    </li>` : ''}
      `})
    }
    str += `</ul>`;
  
    return str;
  }
export enum SubSystem { 
	UM, APP, REG, INT, AUDIT, ADM, MSG, Updater, PRNT, RPT, Retry, IDG, PUB 
}

export enum UserStatus {
    Created = '2d91b03d-2da0-47b2-b00a-0875950441d4',
	Active = 'bba091a3-dfc1-4b39-9958-1759292ff999',
	Disabled = '4d37f215-3737-485b-8139-e3de7ed00e4f',
	Deactivated = 'ea4c4be9-6cff-424d-ad2c-8aad0b862d1c'
}

export enum ActionInitiatorType {
	User = '72804db9-0009-42d7-aa26-5d9362a19a7d',
	Import = 'f364d015-f7f3-42f6-a961-c8cec63cebe3',
	ExternalSystem = '34a56649-d845-4415-9f63-1345c12fcb8c',
	Integration = 'f0f223f6-d825-488c-8fbe-db23ca9ed154',
	CronJob = '225f6780-342c-48ca-a894-0f22cb6ad0e6'
}

export enum ApplicationStatus {
	Pending = 'b7b6b046-015a-415e-a7ba-12b96f0a609b',
	Supplement = '6fe64c26-008a-4de2-bcb1-1d70a5d8ead7',
	Rejected = 'b56dba29-dbb4-475d-afbf-8a1983c86133',
	Closed = '08f7b592-5bc2-4cd1-9aa2-8ac590520eae',
	Approved = 'dc565eb5-32c0-49fc-91f3-b15fb1f6b5d8',
	Canceled = '9822bae1-adc9-4242-9230-0b65bb88fe47',
	Completed = '8cb3ad75-6d58-458c-b12e-a62098f913c8',
	InProcess = 'd1c193b6-1df6-40d6-96e6-4ce093ab28ea'
}

export enum SystemResourceType {
	ClassifierDomain = 'ClassifierDomain',
	CronJobConfiguration = 'CronJobConfiguration',
	EventNotificationConfig = 'EventNotificationConfig',
	IdGenerationConfig = 'IdGenerationConfig',
	Institution = 'Institution',
	Language = 'Language',
	MenuItem = 'MenuItem',
	MessageTemplate = 'MessageTemplate',
	Register = 'Register',
	RegisterFunctionality = 'RegisterFunctionality',
	RegisterService = 'RegisterService',
	Role = 'Role',
	SystemUser = 'SystemUser',
	Translation = 'Translation',
	AccountTypeConfiguration = 'AccountTypeConfiguration',
	SMSProviderConfig = 'SMSProviderConfig'
}

export enum FieldValueGroup {
	Default = '9697403a-47da-44cc-8b14-654b77b9cd16'
}
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IConfig } from '../../../models/config.interface';
import { DefaultParams } from '../../../models/filter.model';
import { HelperService } from '../../../services/helper.service/helper.service';
import { HttpService } from '../../../services/http-service/http.service';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { TableColumn } from '../../../models/table-column.model';
import { routesEnum } from '../../../enumerators/routesEnum';
import { ApplicationStatus } from '../../../enumerators/classifier.enum';

@Component({
  selector: 'app-incomplete-applications',
  templateUrl: './incomplete-applications.component.html',
  styleUrls: ['./incomplete-applications.component.scss']
})
export class IncompleteApplicationsComponent extends BaseListComponent {
  searchData = new DefaultParams('TrackingInfoEntity.CreationDate desc');
  defaultSearchData = new DefaultParams('TrackingInfoEntity.CreationDate desc');
  actions: string[] = ['edit'];

  tableColumns = [
    new TableColumn('TrackingInfoEntity.TrackingNumber', 'pages.myOffice.trackingNo'), 
    new TableColumn('TrackingInfoEntity.CreationDate', 'pages.myOffice.creationDate', 'date'),
    new TableColumn('TrackingInfoEntity.RegisterServiceId', 'pages.myOffice.registerSerice', 'guid', "REGISTRIES_SERVICES"),
    new TableColumn('TrackingInfoEntity.ApplicationStatusId.Value', 'pages.myOffice.status', 'guid', "APPLICATION_STATUS", 'ApplicationStatusId'),
    new TableColumn(null, null, 'action')
  ];
  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected readonly keycloak: KeycloakService,
    protected location: Location,
    protected httpService: HttpService,
    private helper: HelperService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService);
    this.apiUrl = `${this.environment.api.myOffice}/IncompleteApplications`;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }  

  public onSearchChange(value: string): void {    
    this.searchData['TrackingNumberLike'] = value;
    this.searchData['RegisterServiceNameLike'] = value;
    this.loadList();
  }

  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.service}/selectSearch`).subscribe(res => {
      this.classifierData.REGISTRIES_SERVICES = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
    this.httpService.get(`${this.environment.api.classifier}/selectSearch?ClassifierDomainNaturalIds=APPLICATION-STATUS&translationLanguage=${this.helper.getCurrentSystemLanguage()}`).subscribe(res => {
      this.classifierData.APPLICATION_STATUS = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
  }

  protected override onEdit(item: any): void {
    void this.router.navigate([routesEnum.form.url], { relativeTo: this.activatedRoute, queryParams: { FormCode: item.FormCode, Id: item.Id } });
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { PageEvent, MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagging',
  templateUrl: './pagging.component.html',
  styleUrls: [ './pagging.component.scss' ]
})
export class PaggingComponent extends MatPaginatorIntl implements OnInit, OnDestroy {
  pageSizeArray = [5, 10, 15, 20];
  @Input() page = 1;
  @Input() pageSize = 5;
  @Input() total: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() pageSizeChange: EventEmitter<number>;
  @Output() pageChange: EventEmitter<number>;

  itemsPerPageLabel: string;
  firstPageLabel: string;
  previousPageLabel: string;
  nextPageLabel: string;
  lastPageLabel: string;
  subscriptions: Array<Subscription> = [];

  constructor(private translateService: TranslateService) {
    super();

    this.pageSizeChange = new EventEmitter();
    this.pageChange = new EventEmitter();

    this.updatePaginatorTranslations();

    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => {
      this.updatePaginatorTranslations();
    }));
  }

  ngOnInit(): void {
    this.page = +this.page;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public setPage(event: PageEvent): void {
    if(+event.pageSize !== +this.pageSize) {
      this.pageSizeChange.emit(event.pageSize);
    }

    if(+event.pageIndex !== +this.page - 1) this.pageChange.emit(+event.pageIndex + 1);
  }

  updatePaginatorTranslations() {
    this.itemsPerPageLabel = this.translateService.instant('pages.common.paginator.itemsPerPageLabel');
    this.firstPageLabel = this.translateService.instant('pages.common.paginator.firstPageLabel');
    this.previousPageLabel = this.translateService.instant('pages.common.paginator.previousPageLabel');
    this.nextPageLabel = this.translateService.instant('pages.common.paginator.nextPageLabel');
    this.lastPageLabel = this.translateService.instant('pages.common.paginator.lastPageLabel');

    this.changes.next();
  }
}

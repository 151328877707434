import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
    providedIn: 'root'
  })
export class LocalStorageService {

    public KEY_SYSTEM_LANGUAGE = "SYSTEM_LANGUAGE";
    public KEY_LANGUAGE_LIST = "SYSTEM_LANGUAGE_LIST" 
    public KEY_LANGUAGE_ID = "SYSTEM_LANGUAGE_ID"
    public KEY_PERMISSIONS_CREATE = "PERMISSIONS_CREATE"
    // token can be replaced with SESSION_STORAGE if needed
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

    public store(key: any, value: any): void {
        this.storage.set(key, value);
    }

    public retrieve(key: string): any {
        return this.storage.get(key);
    }

    public exists(key: string): boolean {
        return this.storage.has(key);
    }

}
import { Injectable } from '@angular/core';
import { SessionModalComponent } from '../../popups/session-modal/session-modal.component';

@Injectable()
export class ModalStackService {

  private modalStack: SessionModalComponent[] = [];

  constructor() { }

  pushModal(modal: SessionModalComponent) {
    this.modalStack.push(modal);
    modal.pushModalToTop();
  }

  popModal() {
    this.modalStack.pop();
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { routesEnum } from "../../enumerators/routesEnum";
import { IConfig } from "../../models/config.interface";
import { HttpService } from "../../services/http-service/http.service";
import { ToastrServiceExt } from "../../services/toastr.service/toastr.service";
import { APP_CONFIG } from "../../utility/tokens";
import { SubSystem } from "../../enumerators/classifier.enum";


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['register.component.scss']
})

export class RegisterComponent implements OnInit {

    routesEnum = routesEnum;
    RegisterId: string;
    registerQueryParam = {};
    functionalities: Array<any> = [];
    currentFunctionality: any;

    module: SubSystem = SubSystem.APP;

    constructor(protected activatedRoute: ActivatedRoute,
        protected toastrService: ToastrServiceExt,
        protected httpService: HttpService,
        private router: Router,
        @Inject(APP_CONFIG) private readonly environment: IConfig) {
    }

    ngOnInit(): void {
    }
}

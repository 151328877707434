import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Pipe({
    name: 'dynamicTranslate',
    pure: true
})
export class DynamicTranslatePipe implements PipeTransform, OnDestroy {

    subscription: Subscription;

    constructor(private translateService: TranslateService) { }

    transform(key: string): Observable<string> {

        // observable is needed to subscribe to language change event
        const subscription = new Observable<string>(observer => {
            if (!key) {
                observer.next();
                return;
            }
            
            this.translateService.get(key).subscribe(translation => {
                observer.next(translation)
            })  

            // on language change we get new classifier list
            this.translateService.onLangChange.subscribe(() => {
                this.translateService.get(key).subscribe(translation => {
                    observer.next(translation)
                });
            })
        });

        return subscription;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
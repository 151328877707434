import webform from 'formiojs/Webform';
import _ from 'lodash';

export default class WebformExtended extends webform {

    setValue(submission, flags = {}) {                 
        if (submission && !submission.data) {
            const sub = submission;
            submission = { data: {} };
            submission.data = sub;
        }
    
        const changed = super.setValue(submission, flags);       
    
        return changed;
      }
    
      getValue() {
        if (!this._submission.data) {
          this._submission.data = {};
        }
        if (this.viewOnly) {
          return this._submission;
        }
        const submission = this._submission;
        submission.data = this.data;
        return this._submission.data;
      }       
}
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { routesEnum } from '../../../enumerators/routesEnum';
import { DefaultParams } from '../../../models/filter.model';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { TableColumn } from '../../../models/table-column.model';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { HttpService } from '../../../services/http-service/http.service';
import { Location } from '@angular/common';
import { HelperService } from '../../../services/helper.service/helper.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { SignalRService } from '../../../services/signalr.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Guid } from 'guid-typescript';
import { formEnum } from '../../../enumerators/Forms.enum';

export class NotificationDTO {
  Id: Guid;
  Body: string;
  CreationDateTime: Date;
  IsRead: Boolean;
  Title: string;
}

@Component({
  selector: 'app-push-notification-list',
  templateUrl: './push-notification-list.component.html',
  styleUrls: ['./push-notification-list.component.scss']
})

export class PushNotificationListComponent extends BaseListComponent implements OnInit, OnDestroy {

  searchData = new DefaultParams('CreationDateTime desc');
  defaultSearchData = new DefaultParams('CreationDateTime desc');
 
  selectedLanguageId: string;
  apiUrl = this.environment.api.notifications + '/searchList';
  listUrl = routesEnum.pushNotificationList.url;
  actions: string[] = ['view', 'delete'];

  pageNotifications: Array<NotificationDTO>;
  imageUrl = '/assets/images/demo-notification.jpg';
  protected subscriptions: Array<Subscription> = [];

  tableColumns = [
    new TableColumn(null, null, 'select'),
    new TableColumn('CreationDateTime', 'pages.pushNotificationList.data.creationDate', 'date'),
    new TableColumn('Title', 'pages.pushNotificationList.data.title'),
    new TableColumn('Body', 'pages.pushNotificationList.data.body', 'limitStringLength'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected readonly keycloak: KeycloakService,
    protected location: Location,
    protected httpService: HttpService,
    private signalRService: SignalRService,
    private helper: HelperService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService);
    this.loadClassifiers();
  }

  ngOnInit() {
    this.imageUrl = '/assets/images/' + this.environment.env.projectCode + '-notification.jpg';
    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => {
      this.loadClassifiers();
    }));

    this.subscriptions.push(this.signalRService.notificationEventHandler.subscribe(x => {
      if (x) {
          this.loadList();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  protected override loadClassifiers(): void {
    this.selectedLanguageId = this.helper.getCurrentSystemLanguageId();
    this.searchData = Object.assign({}, new DefaultParams('CreationDateTime desc'), {
      LanguageId: this.selectedLanguageId,
      IsDeleted: 'false'
    })
    this.classifiersLoading = false;
    this.loadList();
  }

  onDeleteMarked(itemsIds) {
    this.openDeleteConfirmationModal();
    this.modalRef.content.clickEvent.subscribe(res => {
      if (res.okEvent) {
        this.apiService.post(`${this.environment.api.notifications}/delete`, itemsIds).subscribe(() => {
          this.loadList();
          this.toastr.success('Item deleted');
        }, (err) => {
          this.toastr.serverError(err);
        });
      }
    });
  }

  onEditMarked(itemsId) {
    this.httpService.post(`${this.environment.api.notifications}/markRead`, itemsId).subscribe(res => {
      this.loadList();
    }, (err) => {
      this.toastr.serverError(err);
    });
  }

  protected override onDelete(item: any): void {
    this.openDeleteConfirmationModal();

    this.modalRef.content.clickEvent.subscribe(res => {
      if (res.okEvent) {
        let guidDeleteList = [];
        guidDeleteList.push(item.Id);
        this.apiService.post(`${this.environment.api.notifications}/delete`, guidDeleteList).subscribe(() => {
          this.loadList();
          this.toastr.success('Item deleted');
        }, (err) => {
          this.toastr.serverError(err);
        });
      }
    });

  }

  protected override onView(item: any): void {
    this.router.navigate([routesEnum.notificationViewer.url], { queryParams: { Id: item.Id, LanguageId: this.selectedLanguageId } });
  }

  protected override setQueryParams(): void {
    if (this.selectedLanguageId) {
      void this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.searchData,
        replaceUrl: this.initialLoad
      });
    }
  }

  protected override loadList(backToPageOne = true): void {
    if (backToPageOne) {
      this.searchData.Page = 1;
    }

    this.beforeLoadList();
    
    this.apiService.getList(this.apiUrl, this.searchData).subscribe((response: any) => {
      this.pageNotifications = response;

      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.toastr.serverError(err);
    })
  }

  onFilter() {
    if(!this.searchData['NotificationLike']) {
      this.loadList();
    }
    
    this.apiService.getList(this.apiUrl, this.searchData).subscribe((response: any) => {
      this.pageNotifications = response;

      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.toastr.serverError(err);
    });

    this.searchData.Page = 1;
  }
}
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserActivityService } from '../../services/user-activity/user-activity.service';
import { SessionModalComponent } from '../session-modal/session-modal.component';

@Component({
  selector: 'app-user-session-timeout',
  templateUrl: './user-session-timeout.component.html',
  styleUrls: ['./user-session-timeout.component.scss']
})
export class UserSessionTimeoutComponent implements OnInit, OnDestroy {

  @ViewChild(SessionModalComponent) modal: SessionModalComponent;
  constructor(private userActivityService: UserActivityService) { }
  subscriptions = [];

  ngOnInit(): void {
    this.subscriptions.push(this.userActivityService.getUserInactivity().subscribe(show => this.canBeShown(show)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  failedToRefreshToken(): boolean {
    return this.userActivityService.tokenRefreshFailed;
  }

  secondsRemaining(): number {
     return this.userActivityService.automaticLogoutCountdown;
  }
  async sessionContinueAction(): Promise<void> {
    this.modal.hideModal();
    await this.userActivityService.userContinueSession();
  }

  sessionLogoutAction(): void {
    this.userActivityService.userEndSession();
  }

  sessionCloseAction(): void {
    this.sessionLogoutAction();
  }

  canBeShown(show: boolean): void {
    if (show) {
      this.modal.showModal();
    }
  }
  showModal(): void {
    this.modal.showModal();
  }

  displaySecondsRemaining(): string {

    const m = Math.floor(this.userActivityService.automaticLogoutCountdown / 60);
    const s = this.userActivityService.automaticLogoutCountdown % 60;
    return `${this.zeroPad(m)}:${this.zeroPad(s)}`;
  }

  zeroPad(number: number): string {
    let res = `${number}`;
    if (number < 10) {
      res = `0${number}`;
    }
    return res;
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum'
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from '../../../../services/toastr.service/toastr.service';
import { HelperService } from '../../../../services/helper.service/helper.service';
import { IConfig } from '../../../../models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent extends BaseListComponent implements OnInit {
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');

  listUrl = routesEnum.accountList.url;
  formEnum = formEnum.accountForm;

  tableColumns = [
    new TableColumn('AccountTypeId', 'pages.account.list.data.accountType', 'guid', "accountTypes"),
    new TableColumn('AccountName', 'pages.account.list.data.accountName'),    
    new TableColumn('IdentifierTypeId', 'pages.account.list.data.identifierType', 'guid', "IdentifierTypes"),
    new TableColumn('Identifier', 'pages.account.list.data.identifier'),
    new TableColumn('CreatedOn', 'pages.account.list.data.createdOn', 'datetime')    
  ];


  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected readonly keycloak: KeycloakService,
    protected httpService: HttpService,
    protected location: Location,
    protected activatedroute: ActivatedRoute,
    protected helperService: HelperService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedroute, keycloak, location, httpService);
    this.apiUrl = this.environment.api.umAccounts;    
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }

  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=ACCOUNT-TYPE`).subscribe(res => {
      this.classifierData.accountTypes = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=IDENTIFICATION_TYPE`).subscribe(res => {
      this.classifierData.IdentifierTypes = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
    this.httpService.get(`${this.environment.api.classifier}/SelectSearch?ClassifierDomainNaturalIds=COUNTRY`).subscribe(res => {
      this.classifierData.IdentifierIssuedBy = res;
      this.classifiersLoading = false;
    }, (err) => {
      this.toastrService.serverError(err);
    });
  }

}

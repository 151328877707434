import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseMenuComponent } from '../../../shared/base-menu/base-menu';
import { MenuService } from 'projects/urp/src/app/services/menu.service/menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  
})

export class SidebarComponent extends BaseMenuComponent {
  constructor(protected menuService: MenuService) {
    super(menuService);
  }
}

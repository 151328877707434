
/* eslint @typescript-eslint/no-explicit-any: 0, @typescript-eslint/explicit-module-boundary-types: 0 */
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { IService } from '../../../services/service.interface';
import { routesEnum } from '../../../enumerators/routesEnum';
import { formEnum } from '../../../enumerators/Forms.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal/modal.component';
import { ListQueryparams } from '../../../models/list-query-params.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base-list',
  template: ''
})
export class BaseListComponent implements OnInit, OnDestroy {
  protected classifierData: any = {};
  protected subscriptions: Subscription[] = [];
  modalRef?: BsModalRef;
  isCollapsed = false;
  initSearchData;
  searchData;
  defaultSearchData;
  loading = false;
  filtering = false;
  classifiersLoading = true;
  initialLoad = true;
  tableData: any;
  tableOptions: any;
  parentInfo: any;
  totalItem: number;
  nonQueryData: any = {};
  subscription$: Subscription = null;

  apiUrl: string;
  actions: string[] = ['view', 'delete', 'edit'];
  listUrl = routesEnum.institutionList.url; // for formio
  formEnum = formEnum.institutionForm;
  createQueryParams: ListQueryparams; // Classifier overwrites this with specific params

  isFilterClosed = true;

  constructor(protected toastr: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected readonly keycloak: KeycloakService,
    protected location: Location,
    @Inject('IService') protected apiService: IService) { }


  ngOnInit(): void {
    this.loading = true;
    this.initSearchData = Object.assign({}, this.defaultSearchData);
    this.getQueryParams();
    this.loadClassifiers();
    this.initialLoad = false;
    this.tableOptions = {};
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if(this.subscription$ != null){
      this.subscription$.unsubscribe();
    }
  }

  openDeleteConfirmationModal(): void {
    this.modalRef = this.modal.show(ModalComponent);
    this.modalRef.content.okButtonName = 'pages.common.form.okButton';
    this.modalRef.content.cancelButtonName = 'pages.common.form.cancelButton';
    this.modalRef.content.title = 'confirmationTitles.deleteApplicationTitle';
    this.modalRef.content.message = 'confirmationMessages.deleteMessage';
    this.modalRef.content.isDeletion = true;
  }

  protected loadClassifiers(): void {
    this.classifiersLoading = false;
  }

  protected onFilter(): void {
    this.loadList();
  }

  protected clearFilter(): void {
    this.searchData = Object.assign({}, this.defaultSearchData);
    this.loadList();
  }

  protected closeFilter(){
    this.isFilterClosed = true;
  }

  protected openFilter(){
    this.isFilterClosed = false;
  }

  protected onBack(): void {
    this.location.back();
  }

  protected async onDownload(item: any): Promise<void> {  }

  onSelectChange(data: any): void {
    const propertyName = data["propertyName"];
    const propertyValue = data["propertyValue"];
    this.searchData[propertyName] = propertyValue;
  }

  protected onSortChange(sort: string): void {
    if (sort != '')
      this.searchData.SortBy = sort;
    else {
      const oldSearchData = this.defaultSearchData;
      this.searchData.SortBy = oldSearchData.SortBy;
    }
    this.loadList();
  }

  protected onPageSizeChange(pageSize: number): void {
    this.searchData.Page = 1;
    this.searchData.PageSize = pageSize;
    this.loadList();
  }

  protected onPageChange(page: number): void {
    this.searchData.Page = page;
    this.loadList(false);
  }

  protected loadList(backToPageOne = true): void {
    if (backToPageOne) {
      this.searchData.Page = 1;
    }
    this.beforeLoadList();
    
    for (let key in this.searchData) {
      if (typeof this.searchData[key] === 'boolean') {
        this.searchData[key] = this.searchData[key].toString();
      }
    }

    const queryOptions = {
      ...this.searchData,
      ...this.nonQueryData
    };

    this.apiService.getList(this.apiUrl, queryOptions).subscribe((res: any) => {
        this.tableData = res.Data;
        this.totalItem = res.Total;
        this.loading = false;
        this.afterLoadList();
      }, (err) => {
        this.loading = false;
        this.toastr.serverError(err);
      }
    )
  }

  protected beforeLoadList(): void {
    this.setQueryParams();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected afterLoadList(): void { }

  protected getQueryParams(): void {
    if(this.subscription$ == null){
      this.subscription$ = this.activatedRoute.queryParamMap.subscribe(queryParams => {

        this.searchData = Object.assign({}, this.searchData, this.initSearchData);

        for (const key of queryParams.keys) {
          const values = queryParams.getAll(key);
            if (values.length === 1) {
              this.searchData[key] = values.shift();
            }
            else {
              this.searchData[key] = values;
            }    
        }
        this.beforeLoadList();
      });
    }
  }

  protected setQueryParams(): void {
    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.searchData,
      replaceUrl: this.initialLoad
    });
  }

  protected getParentInformation(url: string) {
    this.apiService.get(url).subscribe(response => {
      this.parentInfo = response;
    },
      (err) => {
        this.toastr.serverError(err);
      })
  }

  protected onCreate(): void {
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: this.createQueryParams ?? { FormCode: this.formEnum } });
  }

  protected onEdit(item: any): void {
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: { FormCode: item.FormCode ?? this.formEnum, Id: item.Id } });
  }

  protected onView(item: any): void {
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: { FormCode: item.FormCode ?? this.formEnum, Id: item.Id, readOnly: true } });
  }

  protected onClaim(item: any): void { }

  protected onReassign(item: any): void { }

  protected onDelete(item: any): void {
    this.openDeleteConfirmationModal();

    this.modalRef.content.clickEvent.subscribe(res => {
      if (res.okEvent) {
        this.apiService.delete(`${this.apiUrl}/${item.Id}`).subscribe(() => {
          this.loadList();
          this.toastr.success('Item deleted');
        }, (err) => {
          this.toastr.serverError(err);
        });
      }
    });
  }
  backClicked() {
    this.location.back();
  }
}

<div *ngIf="!loading" class="flex-row d-flex justify-content-start h-100" style="min-height: 100vh;">
  <div class="flex-column d-flex w-100">
      <div id="main" class="cp-ps-0 cp-pe-0 cp-pt-0 page--inbox">
          <div class="cp-page-header" [ngStyle]="{'background-image':'url(' + imageUrl + ')'}">
              <div class="wrap wrap--full d-flex flex-column flex-sm-row justify-content-between align-items-lg-center">
                  <h1 class="text-white cp-font-weight-700 cp-mb-0">{{'pages.pushNotifications.list.title' | translate}}</h1>
                  <label class="cp-page-header__search">
                      <input class="cp-input cp-input--search" type="text" placeholder="{{'pages.messageTemplate.list.searchPlaceholder' | translate}}"
                             matInput
                             (keyup)="onFilter()"
                             [(ngModel)]="searchData['NotificationLike']">
                  </label>
              </div>
          </div>
      </div>

   <ng-container>
      <app-push-notification-data-table *ngIf="!classifiersLoading" [(tableData)]="pageNotifications.Data" [tableColumns]="tableColumns"
          [listActions]="actions" (edit)="onEdit($event)" (view)="onView($event)" (delete)="onDelete($event)" 
          (editMarked)="onEditMarked($event)" (deleteMarked)="onDeleteMarked($event)" (sortChange)="onSortChange($event)">
      </app-push-notification-data-table>
      <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="pageNotifications.Total"
          (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)">
      </app-pagging>
  </ng-container> 
  </div>
</div>
import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { MessageSubTemplate, MessageTemplate } from 'projects/urp/src/app/models/messageTemplate.model';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';


@Component({
  selector: 'app-message-template-editor',
  templateUrl: './message-template-editor.component.html',
  styleUrls: ['./message-template-editor.component.scss']
})
export class MessageTemplateEditorComponent {

  model;
  subTemplates;
  areLanguagesLoaded = false;
  isMessageTemplateLoaded = false;
  languages = [];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
              
    this.model = new MessageTemplate();
  }

  ngOnInit(): void {
    this.httpService.get(`${this.environment.api.language}`).subscribe(res => {
      this.languages = res.Data;
      this.areLanguagesLoaded = true;
      if(this.isMessageTemplateLoaded) {
        this.loadAllSubTemplates();
      }
  }, (err) => {
      this.toastrService.serverError(err);
  });

    this.route.queryParams.subscribe(params => {
      const id = params.id;
      if(id) {
        this.httpService.get(`${this.environment.api.messageTemplate}/${id}`).subscribe(res => {
          this.model = res as MessageTemplate;
          this.subTemplates = this.model.MessageSubTemplates;
          this.isMessageTemplateLoaded = true;
          if(this.areLanguagesLoaded) { 
            this.loadAllSubTemplates();
          }
        }, (err) => {
          this.toastrService.serverError(err);
        });
      }
      else {
        this.subTemplates = [];
        this.isMessageTemplateLoaded = true;
        if(this.areLanguagesLoaded) { 
          this.loadAllSubTemplates();
        }  
      }
    });
  }

  loadAllSubTemplates() : void {
    if(this.isMessageTemplateLoaded && this.areLanguagesLoaded) {
      this.languages.forEach(language => {
        let doesDuplicateExist = false;
        this.subTemplates.forEach(subTemplate => {
          if(language.Id === subTemplate.LanguageId) {
            subTemplate.LanguageCode = language.Code;
            doesDuplicateExist = true;
          }
        });
        if(doesDuplicateExist === false) {
          let newTemplate = new MessageSubTemplate();
          newTemplate.LanguageCode = language.Code;
          newTemplate.LanguageId = language.Id;
          this.subTemplates.push(newTemplate); 
        }
      });
    }
  }

  onSaveEntity() : void {
    this.model.MessageSubTemplates = this.subTemplates;
      const payload = Object.assign({}, this.model);
      this.httpService.post(`${this.environment.api.messageTemplate}`, payload).subscribe(res => {
        this.toastrService.success('Saved');
        this.model = res as MessageTemplate;
        this.subTemplates = this.model.MessageSubTemplates;
        this.isMessageTemplateLoaded = true;
        if(this.areLanguagesLoaded) {
          this.loadAllSubTemplates();
        }
      }, (e) => {
        this.error(e);
      });
  }
  protected error(errormessage: any) {
    this.toastrService.serverError(errormessage);
  }


}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { IBreadCrumb } from './breadcrumb.interface';
import { filter, distinctUntilChanged} from 'rxjs/operators';
import { HelperService } from '../../../services/helper.service/helper.service';
import { RegisterService } from '../../../services/register.service/register.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];
  register: any;
  /*@Input("isHeader")
  isHeader = false;*/
  @Input() isHeader = false;
  registerPath = 'register';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helper: HelperService,
    private registerService: RegisterService
  ) { 
    this.registerService.register.subscribe(reg => {
      if(reg) {
        this.register = reg;
        if(reg.Name) {
          this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        }
      }
  });
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    })

  }

  isActiveUrl(url: string) : boolean {
    return this.helper.isActiveRoute(url);
  }

  buildBreadCrumb(route: ActivatedRoute, url = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {

    if(route.routeConfig && route.routeConfig.path === this.registerPath && this.register && this.register.Name) {
      route.routeConfig['data'] =  { breadcrumb: this.register.Name, queryParams: true } 
    }

    const oldBreadCrumbs = this.breadcrumbs;
     //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    const queryParams = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.queryParams : false;
    let params;
     

     // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if(isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    if(queryParams && !!route.snapshot && !!route.snapshot.queryParams && Object.keys(route.snapshot.queryParams).length > 0) {
      params = Object.assign({},  route.snapshot.queryParams);
      path = lastRoutePart;
    }

 
    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    //TODO: TPL prasosi refactor?
    let oldItemParams = oldBreadCrumbs&&oldBreadCrumbs.length>0?
                                oldBreadCrumbs.find(item => item.url == nextUrl)?
                                          oldBreadCrumbs.find(item => item.url == nextUrl).params
                                          :''
                                :'';

    if(this.register && this.register.Id && url.includes('/register')) {
      if(!oldItemParams) {
        oldItemParams = {};
      }
      oldItemParams['RegisterId'] = this.register.Id;
    }
     const breadcrumb: IBreadCrumb = {
         label: label,
         url: nextUrl,
         params: oldItemParams?oldItemParams:params
     };

     // Only adding route with non-empty label
     const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
     if (route.firstChild) {
         //If we are not on our current path yet,
         //there will be more children to look after, to build our breadcumb
         return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
     }
     return newBreadcrumbs;
  }

}

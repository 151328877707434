<ng-container
  *ngIf="isHeader; then header; else body">
</ng-container>

<ng-template #header>
  <div class="header-breadcrumbs container-fluid">
  <ul>
      <li *ngFor="let breadcrumb of breadcrumbs" >
        <a translate *ngIf="!isActiveUrl(breadcrumb.url);else labelOnly" [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.params">
          {{ breadcrumb.label }}
        </a>
        <ng-template #labelOnly>{{ breadcrumb.label |translate }}</ng-template>
      </li>
    </ul>
  </div>
  </ng-template>

<ng-template #body>
<ol class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs">
      <span translate [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.params" routerLinkActive="router-link-active">
        {{ breadcrumb.label }}
      </span>
    </li>
  </ol>
</ng-template>
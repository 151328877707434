import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IConfig } from '../../models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { CustomHttpHeader } from '../../enumerators/common.enum';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private readonly environment: IConfig) { 
  }

  getPermissionsForResourceCreation(): Observable<unknown> {
    let headers = new HttpHeaders();
    headers = headers.set(CustomHttpHeader.NoSpinner, '1');
    return this.http.get(`${this.environment.api.security}/GetPermissionsForResourceCreation`, { headers: headers });
  }

}

import { Formio } from '@formio/angular';

export const PluginHooks = {
    priority: 0,
    // init(formio): void {
    // },
    preRequest(requestArgs): void {
        const token = Formio.getToken();
		
		requestArgs.opts = !!requestArgs.opts ? requestArgs.opts : {};
		
        if (token) {
            if (requestArgs.opts.header) {
                requestArgs.opts.header.append('Authorization', 'Bearer ' + Formio.getToken());
            }
            else {
                requestArgs.opts.header = new Headers();
                requestArgs.opts.header.append('Authorization', 'Bearer ' + Formio.getToken());
            }
        }
        requestArgs.opts.noToken = true;
    }
    // request(requestArgs): void {
    // },
    // wrapRequestPromise(promise, requestArgs): any {
    //     return promise;
    // },
    // preStaticRequest(requestArgs): void {
    // },
    // staticRequest(requestArgs): void {
    // },
    // wrapStaticRequestPromise(promise, requestArgs): any {
    //     return promise;
    // }
};

import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { DefaultParams } from '../../../../models/filter.model'
import { SignalRService } from 'projects/urp/src/app/services/signalr.service';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';


@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss']
})
export class HeaderNotificationsComponent implements OnInit {
  public token: string;
  notificationMenuShown = false;
  params: any;
  selectedLanguage = '';
  selectedLanguageId = '';
  anyUnreadNotifications: boolean;
  public notifications = [];

  constructor(public keycloak: KeycloakService,
    protected route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private helper: HelperService,
    private toastr: ToastrServiceExt,
    private eRef: ElementRef,
    private signalRService: SignalRService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
  }

  searchData = new DefaultParams('CreationDateTime asc');
  defaultSearchData = new DefaultParams('CreationDateTime asc');

  public async ngOnInit(): Promise<void> {
    this.httpService.get(`${this.environment.api.notifications}/anyUnreadSystemNotifications`).subscribe(res => {
      this.anyUnreadNotifications = res;
    }, (err) => {
      this.toastr.serverError(err);
    });

    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

    this.signalRService.notificationEventHandler.subscribe(anyUnreadNotifications => {
      this.anyUnreadNotifications = anyUnreadNotifications;
    });
  }

  showNotificationList(): void {
    void this.router.navigate([routesEnum.pushNotificationList.url], { queryParams: { LanguageId: this.params.LanguageId } });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.notificationMenuShown = false;
    }
  }
}

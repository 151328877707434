/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, Inject } from '@angular/core';
import { routesEnum } from '../../../enumerators/routesEnum';
import { DefaultParams } from '../../../models/filter.model';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { formEnum } from '../../../enumerators/Forms.enum';
import { TableColumn } from '../../../models/table-column.model';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { HttpService } from '../../../services/http-service/http.service';
import { Location } from '@angular/common';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-registration-object-files-list',
  templateUrl: './registration-object-files-list.component.html'
})
export class RegistrationObjectFileListComponent extends BaseListComponent {

    searchData = new DefaultParams('FileName desc');
    defaultSearchData = new DefaultParams('AttachmentUploadDateTime desc');
 
    apiUrl = this.environment.api.attachments;
    listUrl = routesEnum.registerFileList.url;
    formEnum = formEnum.rorApplicationForm;
    actions: string[] = ['download'];

    tableColumns = [
        new TableColumn('RegisterServiceName', 'pages.registerFilesList.data.orderedEServiceName'),
        new TableColumn('TrackingNumber', 'pages.registerFilesList.data.trackingNo'),
        new TableColumn('FileName', 'pages.registerFilesList.data.fileName'),
        new TableColumn('AttachmentTypeNaturalKey', 'pages.registerFilesList.data.attachmentType', 'cls', "CLS.ATTACHMENT-TYPE"),
        new TableColumn('AttachmentUploadDateTime', 'pages.registerFilesList.data.attachmentUploadDate', 'datetime'),
        new TableColumn(null, null, 'action')
    ];

    constructor(protected toastrService: ToastrServiceExt,
        protected modal: BsModalService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected readonly keycloak: KeycloakService,
        protected location: Location,
        protected httpService: HttpService,
        private helper: HelperService,
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly environment: IConfig) {
        super(toastrService, modal, router, activatedRoute, keycloak, location, httpService);
    }

    protected override loadClassifiers(): void {
        this.classifiersLoading = true;
        this.httpService.get(`${this.environment.api.classifier}/selectSearch?ClassifierDomainNaturalIds=ATTACHMENT-TYPE`).subscribe(res => {
            this.classifierData.attachmentTypes = res;
            this.classifiersLoading = false;
            this.loadList();
        }, (err) => {
            this.toastrService.serverError(err);
        });
    }

    protected override async onView(item: any): Promise<void> {
        void this.router.navigate([ `${this.listUrl}/form`], { queryParams: this.createQueryParams ?? { formCode: item.FormCode, RegisterId: this.searchData['RegisterId'], id: item.Id, readOnly: true } });
    }

    protected override async onDownload(item: any): Promise<void> {
        this.httpClient.get(item.DownloadUrl, { responseType: 'blob' }).subscribe(res => {
            const file = new Blob([res], { type: 'application/octet-stream' });
            saveAs(file, item.FileName);
        });
    }

    protected override clearFilter(): void {
        this.searchData = Object.assign({}, this.defaultSearchData, {  relativeTo: this.activatedRoute, RegisterId: this.searchData['RegisterId'], RegistrationNumberExact: this.searchData['RegistrationNumberExact'] });
        this.loadList();
    }
}

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a mat-tab-link *ngFor="let link of navLinks" 
        routerLink="{{ link.url }}"
        [queryParams]="{FormCode: link.queryParams.formCode, RegisterServiceId: link.queryParams.RegisterServiceId, RegisterServiceConfigurationId: link.queryParams.RegisterServiceConfigurationId, RegisterServiceWorkflowConfigurationId: link.queryParams.RegisterServiceWorkflowConfigurationId, RegisterServiceRegisterObjectLoadCleanUpConfigurationId: link.queryParams.RegisterServiceRegisterObjectLoadCleanUpConfigurationId}"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">
        <span>{{ link.label | translate }}</span>
    </a>
</nav>
<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
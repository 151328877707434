import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Classifier } from '../models/classifier.model';

@Pipe({
  name: 'selectOptionsTranslate',
  pure: true
})
//TODO: could this be solved better
export class SelectOptionsTranslatePipe implements PipeTransform, OnDestroy {
    
    subscription: Subscription;

    constructor(private translateService: TranslateService) { }

    transform(list: Classifier[]): Observable<Classifier[]> {
        if (list === undefined) {
            return;
        }
        const obj = this.toLabelList(list);

        // observable is needed to subscribe to language change event
        const subscription = new Observable<Classifier[]>(observer => {

            this.translateService.get(obj).subscribe(translationObj => {
                observer.next(this.mergeArray(list, translationObj));
            })  

            // on language change we get new classifier list
            this.translateService.onLangChange.subscribe(() => {
                this.translateService.get(obj).subscribe(translationObj => {
                    observer.next(this.mergeArray(list, translationObj));
                });
            })

        });
      
      return subscription;
    }

    mergeArray(list: Classifier[], translatedList: any): any[] {
        const res = [];
        list.forEach(item => {
            res.push({ value: item.value, label: translatedList[item.label] });
        });
        return res;
    }

    toLabelList(keyValueObject: Classifier[]): string[] {
        return keyValueObject.map(ob => ob.label);
    }

    toObjectList(keyValueDict: string[]): Classifier[] {
        return Object.entries(keyValueDict).map(ob => ({ value: ob[0], label: ob[1] }) );
    }

    ngOnDestroy(): void {
        if (this.subscription){
            this.subscription.unsubscribe();
        }
    }
}
import { Component, Inject, OnInit } from '@angular/core';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html'
})
export class SidebarLogoComponent implements OnInit {

  projectCode = 'mk';

  constructor(@Inject(APP_CONFIG) private readonly environment: IConfig) {
  }
  ngOnInit() {
    this.projectCode = this.environment.env.projectCode;
  }
}

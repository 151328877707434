<mat-select panelClass="dropDown-panel" [value]="selectedLanguage" (valueChange)="onLanguageChange($event)">
  <mat-select-trigger>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 0 16px">
    <i class="me-2 flag-icon flag-icon-{{getLanguageCode(selectedLanguage)}}"></i>
      <span class="c-text-14">{{ selectedLanguageLabel }}</span>
    </div>
  </mat-select-trigger>
  <mat-option *ngFor="let opt of languages" [value]="opt.code">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <i class="me-2 flag-icon flag-icon-{{getLanguageCode(opt.code)}}"></i>
        <span class="c-text-14">{{ opt.label }}</span>
    </div>
  </mat-option>
</mat-select>
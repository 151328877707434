import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SessionStateService {
    private _isLoading = new BehaviorSubject<boolean>(false);
    private _groupTranslations = new BehaviorSubject<any[]>([]);

    constructor() {}

    startLoading() {
        setTimeout(() => {
            this._isLoading.next(true);
        }, 0);
    }

    stopLoading() {
        setTimeout(() => {
            this._isLoading.next(false);
        }, 0);
    }

    isLoading(): Observable<boolean> {
        return this._isLoading.asObservable();
    }

    setGroupTranslations(translations: any[]) {
        setTimeout(() => {
            this._groupTranslations.next(translations);
        }, 0);
    }

    getGroupTranslations() {
        return this._groupTranslations.asObservable();
    }

    getGroupTranslationsValue() {
        return this._groupTranslations.value;
    }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routesEnum } from '../enumerators/routesEnum';
import { AuthGuard } from '../utility/auth.guard';
import { RegisterComponent } from '../components/register/register.component';
import { RegisterServicesListComponent } from '../components/register/services-list/register-services-list.component';
import { APPLICATION_ENDPOINT, APP_CONFIG, REGISTRATION_OBJECT_ENDPOINT,  } from '../utility/tokens';
import { IConfig } from '../models/config.interface';
import { ApplicationFormComponent } from '../components/register/application-form/application-form.component';
import { NavigationGuardService } from '../utility/navigation.guard';
import { RegisterServicesDetailsComponent } from '../components/register/service-details/register-service-details.component';


export const registerRoutes: Routes = [
    {
        path: 'register',
        component: RegisterComponent,
        children: [
            { 
                path: '', redirectTo: routesEnum.registerServicesList.url, pathMatch: 'full' 
            },
            {
                path: routesEnum.registerServicesList.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerServicesList.label }, 
                children: [
                    { path: '', component: RegisterServicesListComponent },
                    {
                        path: routesEnum.registerServiceDetails.url, canActivate: [AuthGuard], data: { breadcrumb: routesEnum.registerServiceDetails.label },
                        children: [
                          { path: '', component: RegisterServicesDetailsComponent },
                          { path: routesEnum.form.url, component: ApplicationFormComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuardService], data: { apiController: APPLICATION_ENDPOINT, breadcrumb: 'breadcrumbs.registerApplication', queryParams: true} },
                        ]
                    },
                    { path: routesEnum.form.url, component: ApplicationFormComponent, canActivate: [AuthGuard], canDeactivate: [NavigationGuardService], data: { breadcrumb: 'breadcrumbs.registerApplication', queryParams: true } }
                ]
            },
            {
                path: ':registerId/functionality',
                loadChildren: () => import('./register-functionality-routing.module').then(m => m.RegisterFunctionalityRoutingModule)
            },
            {
                path: ':registerId/functionality/:functionalityId',
                loadChildren: () => import('./register-functionality-routing.module').then(m => m.RegisterFunctionalityRoutingModule)
            }
        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(registerRoutes)
    ],
    providers: [
        { 
          provide: APPLICATION_ENDPOINT, 
          deps: [APP_CONFIG],
          useFactory: (config : IConfig) => config.api.application
        },
        { 
          provide: REGISTRATION_OBJECT_ENDPOINT, 
          deps: [APP_CONFIG],
          useFactory: (config : IConfig) => config.api.registrationObject
        }
      ],
    exports: [RouterModule]
})
export class RegisterRoutingModule { }

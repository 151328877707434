<!-- <aside class="bg-white navbar navbar-expand-md flex-column d-flex align-items-start border-end border-1 mr-2 h-100 w-0 min-vh-100 sidebar">
        <app-sidebar-logo class="d-flex flex-row align-items-center w-100"></app-sidebar-logo>
        <app-sidebar-menu class="w-100"></app-sidebar-menu>
</aside> -->
<nav class="bg-white navbar navbar-expand-md flex-column d-flex align-items-start border-end border-1 overflow-hidden px-2 h-100 c-md-min-height-100vh w-0 cp-navbar" id="c-retractable">
    <div class="d-flex flex-row align-items-center justify-content-start mb-3 d-none d-lg-block">
        <app-sidebar-logo class="d-flex flex-row align-items-center w-100"></app-sidebar-logo>
    </div>
    <div class="overlay h-100 d-block bg-white closed-nav" id="myNav">
        <div class="navbar-nav d-flex flex-column h-100 mx-auto">
            <div class="d-flex flex-row d-md-none cp-ms-20">
                <button class="bg-transparent border-0 c-text-28" (click)="closeMyNav()">&times;</button>
            </div>
            <app-sidebar-menu class="w-100"></app-sidebar-menu>
        </div>
    </div>
</nav>    
<!-- TODO perkelti filro akordiona cia, o inputus palikti specifiniuose komponentuose -->
<div class="navigation d-lg-block d-none">
    <!--<div class="navigation-left">
        <a translate class="btn--cust btn--clear" id="list-back-btn" (click)="onBack()"  title="Back">pages.common.list.backButton</a>
    </div>-->
    <div *ngIf="!disableAddNew" class="ms-1 navigation-right">
        <a translate class="btn btn-primary" id="list-create-btn" (click)="onCreate()" title="Add new record">{{ btnAddNewTitle }}</a>
    </div>

    <div *ngIf="!disableImportExport" class="ms-1 navigation-right">
        <a translate class="btn btn-outline-primary" id="list-export-btn" (click)="onExport()" title="Export">{{ btnExportTitle }}</a>
    </div>

    <div *ngIf="!disableImportExport" class="ms-1 navigation-right">
        <a translate class="btn btn-outline-primary" id="list-import-btn" (click)="onImport()" title="Import">{{ btnImportTitle }}</a>
    </div>

    <div class="clearfix"></div>
</div>
<!-- Mobile -->
<div class="navigation d-lg-none">
    <a *ngIf="!disableAddNew" translate class="btn btn-primary ms-1" id="list-create-btn" (click)="onCreate()" title="Add new record">{{ btnAddNewTitle }}</a>
    <a *ngIf="!disableImportExport" translate class="btn btn-outline-primary ms-1" id="list-export-btn" (click)="onExport()" title="Export">{{ btnExportTitle }}</a>
    <a *ngIf="!disableImportExport" translate class="btn btn-outline-primary ms-1" id="list-import-btn" (click)="onImport()" title="Import">{{ btnImportTitle }}</a>
</div>
<app-breadcrumb [isHeader]="true"></app-breadcrumb>
<div class="container-fluid c-max-width-755px">
    <div class="w-100 padding-x-16 d-flex my-4">
        <button class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0" [routerLink]="'/myOffice'">
            <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
        </button>
        <h4 class="my-0 mx-3" translate>pages.incomplete.applications.header</h4>
    </div>
    <div class="w-100 padding-x-16">
        <div class="my-3 mx-auto rounded-1 input-group">
            <span class="input-group-text bg-white cp-mb-20 border-radius-left-4 border-DDDBDA">
                <div class="c-icon c-i-search float-start me-2 c-icon-darker-grey"></div>
            </span>
            <input id="FilterValue" name="FilterValue" class="p-2 form-control border-start-0 shadow-none border-DDDBDA"
                placeholder="{{'pages.common.list.searchButton' | translate}}"
                (input)="onSearchChange($event.target.value)"  />
        </div>
    </div>
    <div *ngIf="loading" class="container-fluid">
        <app-loader type="list"></app-loader>
    </div>
    <div *ngIf="!loading" class="table-holder">
        <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" [tableColumns]="tableColumns"
            [listActions]="actions" [classifiers]="classifierData" (edit)="onEdit($event)" (view)="onView($event)"
            (delete)="onDelete($event)" (sortChange)="onSortChange($event)">
        </app-data-table>
    </div>
    <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="totalItem"
        (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)"></app-pagging>
</div>
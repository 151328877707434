import { Component, Inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { DefaultParams } from 'projects/urp/src/app/models/filter.model';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { MessageTemplate } from 'projects/urp/src/app/models/messageTemplate.model';
import { letProto } from 'rxjs-compat/operator/let';


@Component({
  selector: 'app-message-template-viewer',
  templateUrl: './message-template-viewer.component.html',
  styleUrls: ['./message-template-viewer.component.scss']
})
export class MessageTemplateViewerComponent {

  model;
  subTemplates;
  areLanguagesLoaded = false;
  isMessageTemplateLoaded = false;
  languages = [];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected route: ActivatedRoute,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
              
    this.model = new MessageTemplate();
  }

  ngOnInit(): void {
    this.httpService.get(`${this.environment.api.language}`).subscribe(res => {
      this.languages = res.Data;
      this.areLanguagesLoaded = true;
      if(this.isMessageTemplateLoaded) {
        this.loadAllSubTemplates();
      }
    }, (err) => {
      this.toastrService.serverError(err);
    });

    this.route.queryParams.subscribe(params => {
      const id = params.id;
      if(id) {
        this.httpService.get(`${this.environment.api.messageTemplate}/${id}`).subscribe(res => {
          this.model = res as MessageTemplate;
          this.subTemplates = this.model.MessageSubTemplates;
          this.isMessageTemplateLoaded = true;
          if(this.areLanguagesLoaded) {
            this.loadAllSubTemplates();
          }
        }, (err) => {
          this.toastrService.serverError(err);
        });
      }
      else {
        this.subTemplates = [];
        this.isMessageTemplateLoaded = true;
        if(this.areLanguagesLoaded) {
          this.loadAllSubTemplates();
        }
      }
    });
  }

  loadAllSubTemplates() : void {
    let languagesMap = {};
    this.languages.forEach(language => {
      languagesMap[language.Id] = language.Code;
    });
    if(this.isMessageTemplateLoaded && this.areLanguagesLoaded) {
      this.subTemplates.forEach(subTemplate => { 
        subTemplate.LanguageCode = languagesMap[subTemplate.LanguageId];
      });
    }
  }


}

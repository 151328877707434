import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { DefaultParams } from 'projects/urp/src/app/models/filter.model';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-translation-list',
  templateUrl: './translation-list.component.html'
})
export class TranslationListComponent extends BaseListComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  searchData = new DefaultParams('Code asc');
  defaultSearchData = new DefaultParams('Code asc');
  
  listUrl = routesEnum.translationList.url;
  formEnum = formEnum.translationForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Code', 'pages.translation.list.data.shortName', 'notTranslatable'),
    new TableColumn('Text', 'pages.translation.list.data.text', 'notTranslatable'),
    new TableColumn('LanguageName', 'pages.translation.list.data.language', 'notTranslatable'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService); 
    this.apiUrl = this.environment.api.translation;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.Translation);
  }

  protected override beforeLoadList(): void {
    this.searchData["IsClassifier"] = true; // hide all CLS translations by default
    super.beforeLoadList();
  }
  
  protected override loadClassifiers(): void {
    const url = `${this.environment.api.language}/search`;
    
    this.httpService.get(url).subscribe(res => {
        this.classifierData.languages = res;
        this.classifiersLoading = false;

        this.loadList();
      }, 
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  private onImport(): void{
    this.fileInput.nativeElement.click();
  }

  private onExport(): void {
    const tempSearchData = { ...{}, ...this.searchData };
    delete tempSearchData.SortBy;
    delete tempSearchData.PageSize;
    delete tempSearchData.Page;

    const param = this.httpService.getQueryParams(tempSearchData);
    const url = `${this.environment.api.translation}/export?${param}`;

    const httpOptions = {
      responseType: 'blob'
    };

    this.httpService.get(url, httpOptions).subscribe(res => {
      const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "translations.csv";
      link.click();
      link.remove();

      this.classifiersLoading = false;
    },
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'text/csv') {
        this.uploadFile(file);
      } else {
        this.toastrService.serverError('Only csv files are allowed.');
      }
    }
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.post(`${this.environment.api.translation}/import`, formData, httpOptions).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = "translationImportResult.json";
        link.click();
        link.remove();

        this.classifiersLoading = false;
        this.fileInput.nativeElement.value = "";
      },
      (err) => {
        this.toastrService.serverError(err);
        this.fileInput.nativeElement.value = "";
      }
    );
  }

}

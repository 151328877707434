import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "../local-storage.service/local-storage.service";
import { SecurityService } from "../security.service/security.service";
import { SubSystem, SystemResourceType } from 'projects/urp/src/app/enumerators/classifier.enum';

@Injectable()
export class HelperService {

    constructor(
        private storageService: LocalStorageService,
        private translateService: TranslateService,
        private securityService: SecurityService
    ) { }

    getCurrentSystemLanguage(): string {
        const languageInStorage: string = this.storageService.retrieve(this.storageService.KEY_SYSTEM_LANGUAGE);
        const defaultLanguage: string = this.translateService.getDefaultLang();

        return languageInStorage ?? defaultLanguage;
    }

    getCurrentSystemLanguageId(): string { 
        return this.storageService.retrieve(this.storageService.KEY_LANGUAGE_ID);
    }

    isActiveRoute(urlToCompare: string, sidebar?: boolean, queryParams?: Params, params?: Params): boolean {
        let path = window.location.hash;
        if (!path) {
            return false;
        }

        let paramsStartIndex: number;
        let currentUrl: string;
        if (sidebar) {
            paramsStartIndex = path.indexOf('/form?FormCode=') === -1 ? path.indexOf('?') : path.indexOf('/form?FormCode=');
            currentUrl = paramsStartIndex !== -1 ? path.substring(1, paramsStartIndex) : path.substring(1);
            
            if (urlToCompare === '/register') {
                return currentUrl === '/register/servicesList';
            }
            else if (urlToCompare.startsWith('/myOffice')) {
                return currentUrl.startsWith(urlToCompare);
            }
            else if (urlToCompare.startsWith('/domainsList')) {
                return currentUrl.startsWith(urlToCompare);
            }
        }
        else {
            paramsStartIndex = path.indexOf('?');
        }

        currentUrl = paramsStartIndex !== -1 ? path.substring(1, paramsStartIndex) : path.substring(1);
        return urlToCompare === currentUrl;
    }

    setPropAsDictionaryParam(filterObject: any, dictionaryName: string, fieldName: string, fieldValue: string): void {
        if (fieldValue) {
            const key = `${dictionaryName}[${fieldName}]`;
            filterObject[key] = fieldValue;
        }
    }

    setPropAsArrayOfObjectd(filterObject: any, filterPropertyName: string, arrayOfObjects: Array<any>) {
        arrayOfObjects.forEach((elementOfArray, index) => {
            for (const propertyOfElement in elementOfArray) {
                const key = `${filterPropertyName}[${index}].${propertyOfElement}`;
                filterObject[key] = elementOfArray[propertyOfElement];
            }
        });
    }

    loadPermissionsForResourceCreation() {
        this.securityService.getPermissionsForResourceCreation().subscribe(res => {
            this.storageService.store(this.storageService.KEY_PERMISSIONS_CREATE, res);
        });
    }

    hasPermissionForResourceCreation(subSystem: SubSystem, systemResourceType: SystemResourceType) {
        var permissions = this.storageService.retrieve(this.storageService.KEY_PERMISSIONS_CREATE);
        return permissions[SubSystem[subSystem]].includes(SystemResourceType[systemResourceType]);
    }

    /*
    cloneData(data) {
        const _tempStr = JSON.stringify(data);
        const clone = JSON.parse(_tempStr);
        return clone;
      }
    
    formatDate(dateParam: any): string {
        if (dateParam === undefined || dateParam === null) {
            return '';
        }
        const date = new Date(dateParam); //new Date(moment(dateParam, 'YYYY-MM-DD'));
        const dateString: string = date.toLocaleDateString('LT');
        if (dateString === '1-01-01') {
            return '0001-01-01';
        } else {
            return dateString;
        }
    }

    formatValue(value: any, configOfField: any, lang: string) {
        switch (configOfField.format) {
            case 'Number':
            return value[configOfField.name];
            case 'Text':
            return value[configOfField.name];
            case 'Date':
            return this.formatDate(value[configOfField.name]);
            case 'Translation':
            return this.getTranslation(value, lang);
            default:
            break;
        }
    }

    getTranslation(classifierValue: any, lang: string) {
        if (classifierValue && classifierValue['translations']) {
            const tmp = classifierValue['translations'].find(e => e['languageKey'] && e['languageKey'] === lang);
            if (tmp) {
            return tmp['value'];
            }
        }
        return '';
    }

    private getTime(date?: Date) {
        return new Date(date) != null ? new Date(date).getTime() : 0;
    }

    private alphabetically(ascending, a, b) {
        if (a === b) {
            return 0;
        } else if (a === null) {
            if (ascending) {
                return 1;
            } else {
                return -1;
            }
        } else if (b === null) {
            if (ascending) {
            return -1;
            } else {
            return 1;
            }
        } else if (ascending) {
            return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
        } else {
            return a.toLowerCase() < b.toLowerCase() ? 1 : -1;
        }
    }    
    */

}
<div *ngIf="loading" class="container-fluid">
    <app-loader type="list" ></app-loader>
  </div>
  
  <div *ngIf="!loading">
    <app-breadcrumb [isHeader]="true"></app-breadcrumb>
    <div class="bg-secondary px-3">
      <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
        <app-filter-buttons (clickClearFilter)="clearFilter()" (clickCloseFilter)="closeFilter()">
        </app-filter-buttons> 
      </div>
      <div [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
        <div class="justify-content-center">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="form-group">
                  <label class="" for="shortName">{{'pages.registerFilesList.search.trackingNo' | translate}}:</label>
                  <input id="ShortName" name="ShortName" class="form-control" type="text"  (keyup.enter)="onFilter()" [(ngModel)]="searchData.TrackingNumber"/>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                    <app-select *ngIf="!classifiersLoading"
                                            [label]="'pages.registerFilesList.search.attachmentType'"
                                            [parentPropertyName]="'AttachmentTypeIds'" 
                                            [selectedId]="searchData.AttachmentTypeIds"
                                            [list]="classifierData.attachmentTypes | selectOptionsTranslate | async"
                                            (selectChange)="onSelectChange($event)">
                    </app-select>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label class="label--dark"
                        for="UploadDateFrom">{{'pages.registerFilesList.search.attachmentUploadedFrom' | translate}}:</label>
                    <div class="form-element">
                        <input id="UploadDateFrom" name="UploadDateFrom" class="input" type="date"
                            (keyup.enter)="onFilter()" [(ngModel)]="searchData.UploadDateFrom" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div class="form-group">
                    <label class="label--dark"
                        for="UploadDateTo">{{'pages.registerFilesList.search.attachmentUploadedTo'
                        | translate}}:</label>
                    <div class="form-element">
                        <input id="UploadDateTo" name="UploadDateTo" class="input" type="date"
                            (keyup.enter)="onFilter()" [(ngModel)]="searchData.UploadDateTo" />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group pb-3" [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
        <a translate class="btn btn-primary cp-font-weight-700"
          (click)="onFilter()">pages.common.list.searchButton
        </a>
    </div>
</div>

<input #fileInput type="file" (change)="onFileSelected($event)" accept=".json" style="display: none;">

<ng-container>
<div class="table-holder">
    <div class="container-fluid c-max-width-755px">
        <div class="row">
            <div class="col-md-10 d-flex col-sm-12 col-xs-12">    
                <div class="display-table-100">
                    <div class="table-cell-align-vertical-midle cp-pb-8">
                        <button class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0" (click)="backClicked()">
                            <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
                        </button>
                    </div>
                    <div class="table-cell-align-vertical-midle">
                        <h3 class="me-auto" translate>pages.registerFilesList.title</h3>
                    </div>
                </div>
            </div>
            <div *ngIf="isFilterClosed" class="col-md-2 col-sm-12 col-xs-12">
                <a class="btn btn-outline-primary open-filter-margin btn-right float-end" (click)="openFilter()" translate>pages.common.list.filterButton
                    <i class="fa-solid fa-list"></i>
                </a>
            </div>
        </div>
        <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" [tableColumns]="tableColumns"
        [listActions]="actions" (edit)="onEdit($event)" (download)="onDownload($event)" (view)="onView($event)" (delete)="onDelete($event)"
        (sortChange)="onSortChange($event)">
        </app-data-table>
        <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="totalItem"
            (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)"></app-pagging>
    </div>
    </div>
    </ng-container>
</div>

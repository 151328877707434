/**
 * This file shows how to create a custom component and register that within an Angular application.
 *
 * Get the base component class by referencing Formio.Components.components map.
 */
import { Components } from "@formio/angular";
import { isMongoId, eachComponent } from 'formiojs/utils/utils';
import FormExtended from './NestedFormComponents/FormExtended';
const FormComponent = Components.components.form;

export default class FormComponentExtend extends FormComponent {
  constructor(component, options, data) {
    super(component, options, data);
  }
  
  isLoggedIn() {
    return Formio.getToken() !== '';
  }

  init() {
    this.disabled = this.shouldDisabled;
    this._visible = this.conditionallyVisible(null, null);
    if (this.component.addons?.length) {
      this.component.addons.forEach((addon) => this.createAddon(addon));
    }

    this.formObj = {
      display: this.component.display,
      settings: this.component.settings,
      components: this.component.components
    };
    this.valueChanged = false;
    this.subForm = null;
    this.formSrc = '';
    if (this.component.src) {
      this.formSrc = this.component.src;
    }

    if (
      !this.component.src &&
      !this.options.formio &&
      (this.component.form || this.component.path)
    ) {
      if (this.component.project) {
        this.formSrc = Formio.getBaseUrl();
        // Check to see if it is a MongoID.
        if (isMongoId(this.component.project)) {
          this.formSrc += '/project';
        }
        this.formSrc += `/${this.component.project}`;
        this.options.project = this.formSrc;
      }
      else {
        this.formSrc = Formio.getProjectUrl();
        this.options.project = this.formSrc;
      }
      if (this.component.form) {
        if (isMongoId(this.component.form)) {
          if (this.isLoggedIn())
            this.formSrc += `/api/formio/forms/${this.component.form}`;
          else
            this.formSrc += `/api/formio/forms/public/${this.component.form}`;
        }
        else {
          this.formSrc += `/${this.component.form}`;
        }
      }
      else if (this.component.path) {
        this.formSrc += `/${this.component.path}`;
      }
    }

    // Build the source based on the root src path.
    if (!this.formSrc && this.options.formio) {
      const rootSrc = this.options.formio.base + '/api/formio/forms'//this.options.formio.formsUrl;
      if (this.component.form && isMongoId(this.component.form)) {
        this.formSrc = `${rootSrc}/${this.component.form}`;
      }
      else {
        const formPath = this.component.path || this.component.form;
        this.formSrc = `${rootSrc.replace(/\/form$/, '')}/${formPath}`;
      }
    }


    if (this.builderMode && this.component.hasOwnProperty('formRevision')) {
      this.component.revision = this.component.formRevision;
      delete this.component.formRevision;
    }

    // Add revision version if set.
    if (this.component.revision || this.component.revision === 0 ||
      this.component.formRevision || this.component.formRevision === 0
      || this.component.revisionId
    ) {
      this.setFormRevision(this.component.revisionId || this.component.revision || this.component.formRevision);
    }

    return this.createSubForm();
  }

  get emptyValue() {
    return {};
  }

  getComponent(path, fn) {
    path = getArrayFromComponentPath(path);
    if (path[0] === 'data') {
      path.shift();
    }
    const originalPathStr = `${this.path}.${getStringFromComponentPath(path)}`;
    if (this.subForm) {
      return this.subForm.getComponent(path, fn, originalPathStr);
    }
  }

  getSubOptions(options = {}) {
    options.parentPath = `${this.path}`;
    options.events = this.createEmitter();

    // Make sure to not show the submit button in wizards in the nested forms.
    _.set(options, 'buttonSettings.showSubmit', false);

    if (!this.options) {
      return options;
    }
    if (this.options.base) {
      options.base = this.options.base;
    }
    if (this.options.project) {
      options.project = this.options.project;
    }
    if (this.options.readOnly || this.component.disabled) {
      options.readOnly = this.options.readOnly || this.component.disabled;
    }
    if (this.options.breadcrumbSettings) {
      options.breadcrumbSettings = this.options.breadcrumbSettings;
    }
    if (this.options.buttonSettings) {
      options.buttonSettings = _.clone(this.options.buttonSettings);
    }
    if (this.options.viewAsHtml) {
      options.viewAsHtml = this.options.viewAsHtml;
    }
    if (this.options.language) {
      options.language = this.options.language;
    }
    if (this.options.template) {
      options.template = this.options.template;
    }
    if (this.options.templates) {
      options.templates = this.options.templates;
    }
    if (this.options.renderMode) {
      options.renderMode = this.options.renderMode;
    }
    if (this.options.attachMode) {
      options.attachMode = this.options.attachMode;
    }
    if (this.options.iconset) {
      options.iconset = this.options.iconset;
    }
    if (this.options.fileService) {
      options.fileService = this.options.fileService;
    }
    if (this.options.onChange) {
      options.onChange = this.options.onChange;
    }
    return options;
  }

  getValueAsString(value) {
    if (!value) {
      return 'No data provided';
    }
    if (!value && value._id) {
      return value._id;
    }
    if (!value || !Object.keys(value).length) {
      return 'No data provided';
    }
    return '[Complex Data]';
  }

  get subFormData() {
    return this.dataValue || {};
  }

  //nesubmitinam kiekvienos subformos atskirai
  get shouldSubmit() {
    return this.subFormReady && (!this.component.hasOwnProperty('reference') || this.component.reference) && !this.isHidden() && false;
  }

  setValue(submission, flags = {}) {
    const changed = super.setValue(submission, flags);
    this.valueChanged = true;
    if (this.subForm) {
      const revisionPath = submission._frid ? '_frid' : '_vid';
      const shouldLoadOriginalRevision = this.useOriginalRevision
        && (_.isNumber(submission[revisionPath]) || _.isNumber(submission._fvid))
        && _.isNumber(this.subForm.form?.[revisionPath])
        && submission._fvid !== this.subForm.form[revisionPath];

      if (shouldLoadOriginalRevision) {
        this.setFormRevision(submission._frid || submission._fvid);
        this.createSubForm().then(() => {
          this.attach(this.element);
        });
      }
      else {
        this.setSubFormValue(submission, flags);
      }
    }
    return changed;
  }

  setSubFormValue(submission, flags) {
    const shouldLoadSubmissionById = submission
      && submission._id
      && this.subForm.formio
      && _.isEmpty(submission);

    if (shouldLoadSubmissionById) {
      const formId = submission.form || this.formObj.form || this.component.form;
      const submissionUrl = `${this.subForm.formio.formsUrl}/${formId}/submission/${submission._id}`;
      this.subForm.setUrl(submissionUrl, this.options);
      this.subForm.loadSubmission();
    }
    else {
      this.subForm.setValue(submission, flags);
    }
  }

  isEmpty(value = this.dataValue) {
    return value === null || _.isEqual(value, this.emptyValue) || (this.areAllComponentsEmpty(value) && !value._id);
  }  

  createSubForm(fromAttach) {
    this.subFormReady = this.loadSubForm(fromAttach).then((form) => {
      if (!form) {
        return;
      }

      // Iterate through every component and hide the submit button.
      eachComponent(form.components, (component) => {
        this.hideSubmitButton(component);
      });

      // If the subform is already created then destroy the old one.
      if (this.subForm) {
        this.subForm.destroy();
      }

      // Render the form.
      return (new FormExtended(form, this.getSubOptions())).ready.then((instance) => {
        this.subForm = instance;
        this.subForm.currentForm = this;
        this.subForm.parent = this;
        this.subForm.parentVisible = this.visible;
        this.subForm.on('change', () => {
          if (this.subForm) {
            this.dataValue = this.subForm.getValue();
            this.triggerChange({
              noEmit: true
            });
          }
        });
        this.subForm.url = this.formSrc;
        this.subForm.nosubmit = true;
        this.subForm.root = this.root;
        this.subForm.localRoot = this.isNestedWizard ? this.localRoot : this.subForm;
        this.restoreValue();
        this.valueChanged = this.hasSetValue;
        this.onChange();
        return this.subForm;
      });
    }).then((subForm) => {
      this.updateSubWizards(subForm);
      return subForm;
    });
    return this.subFormReady;
  }

  static editForm = customEditForm;
}

export function customEditForm() {
  const editForm = FormComponent.editForm();
  editForm.components[0].components[1].components[0].data.url =
    "/api/formio/forms?subType=subform";
  editForm.components[0].components[1].components[2].data.url =
    "/api/formio/forms/{{ data.form }}";
   return editForm;
}

// Register the component to the Formio.Components registry.
Components.addComponent("form", FormComponentExtend);

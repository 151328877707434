import { Component, Inject, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { LocalStorageService } from 'projects/urp/src/app/services/local-storage.service/local-storage.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { ProcessInstanceDto } from '../../../models/processInstance.model';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { TranslateService } from '@ngx-translate/core';
import { ExceptionTypes, Exception } from '../../../enumerators/exceptionTypes.enum';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { SignalRService } from '../../../services/signalr.service';

@Component({
  selector: 'app-application-form',
  templateUrl: '../../shared/base-form/base-form.component.html',
  styleUrls: ['../../shared/base-form/base-form.component.scss'],
})
export class ApplicationFormComponent extends BaseFormComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected formsService: FormsService,
    protected location: Location,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    protected helper: HelperService,
    protected readonly keycloak: KeycloakService,
    protected modal: BsModalService,
    protected storage: LocalStorageService,
    protected translateService: TranslateService,
    private signalRService: SignalRService,
    protected injector: Injector,
    @Inject(APP_CONFIG) protected readonly environment: IConfig
  ) {
    super(
      route,
      router,
      formsService,
      location,
      toastrService,
      httpService,
      helper,
      keycloak,
      modal,
      storage,
      translateService,
      injector,
      environment
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.signalRService.printoutCompletedEventHandler.subscribe(appId => {
        if (appId && appId === this.params.Id) {
          this.signalPrintoutCompleted(appId);
        }
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected override submition(formData): void {
    this.submission = formData;
    this.submitted = true;
    formData.data.Action = 'Proceed';
    this.save(formData);
  }

  protected onTaskCompleteSuccess(res: any): void {
    this.ignoreSafetyNavigationModal = true;
    const redirectTo = JSON.parse(res.Data).RedirectTo;
    if (redirectTo) {
      if(this.isDeleteButtonPressed) {
        this.toastrService.successTranslatable('pages.common.form.successDeleteMessage');
      }else {
        this.toastrService.successTranslatable('pages.common.form.successSaveMessage');
      }
      void this.router.navigate([redirectTo], { queryParams: { RegisterId: this.params.RegisterId } });
    }
    else {
      this.successSave({ FormCode: res.FormCode, Id: res.Entity.Id, RegisterId: this.params.RegisterId }, res.FormCode, res.FormId);
    }
  }

  protected override save(formData: any): void {    
    formData.data.FormId = this.formId;
    formData.data.FormCode = this.formCode;
    const data = Object.assign({}, formData.data, this.params);
    if (formData.state === 'draft') {
      this.submitted = false;
      this.httpService
        .post(`${this.environment.api.application}`, data)
        .subscribe(
          (res) => {
            this.softSave(res, this.formCode);
            this.refreshForm.emit({
              form: this.form
            });
          },
          (e) => {
            this.error(e);
          }
        );
    } else {
      if (this.params.Id) {
        this.httpService
          .post(`${this.environment.api.tasks}/complete/${this.params.Id}`, data)
          .subscribe(
            (res) => {
              this.onTaskCompleteSuccess(res);
            },
            (e) => {
              if (e.error.domain === ExceptionTypes.ApplicationExceptionType
                && e.error.exception === Exception.ApplicationSubmitConfirmationRequired) {
                if (this.isDeleteButtonPressed) {
                  const modalTitleKey = 'confirmationMessages.deleteTitle';
                  const modalMessageKey = 'confirmationMessages.deleteMessage';
                  forkJoin([
                    this.translateService.get(modalTitleKey),
                    this.translateService.get(modalMessageKey)
                  ]).subscribe(([modalTitle, modalMessage]) => {
                    this.openConfirmationModal(modalTitle, modalMessage, e.error.data.ShowCheckbox, true, true);
                  });
                  this.isDeleteButtonPressed = false;
                }
                else {
                  this.openConfirmationModal(e.error.data.Title, e.error.data.Message, e.error.data.ShowCheckbox, false, true);
                }
                this.modalRef.content.clickEvent.subscribe((res) => {
                  if (res.okEvent) {
                    this.httpService
                      .post(`${this.environment.api.tasks}/complete/${this.params.Id}/true`, data)
                      .subscribe(
                        (res) => {
                          this.onTaskCompleteSuccess(res);
                        },
                        (e) => {
                          this.refreshForm.emit({
                            form: this.form
                          });
                          this.error(e);
                        }
                      );
                  }
                });
              }
              else {
                this.refreshForm.emit({
                  form: this.form
                });
                this.error(e);
              }
            }
          );
      } else {
        data.RegisterId = this.params.RegisterId;
        data.RegisterServiceId = this.params.RegisterServiceId;
        data.StartProcessInstanceDto = new ProcessInstanceDto(
          this.params.RegisterServiceId,
          this.params.ProcessDefinitionKey
        );
        this.httpService
          .post(
            `${this.environment.api.appProcess}/start/${this.params.ProcessDefinitionKey}`,
            data
          )
          .subscribe(
            (res) => {
              this.successSave(res, this.formCode);
            },
            (e) => {
              this.error(e);
            }
          );
      }
    }

    this.clickSuccess.emit(formData.instance);
  }

  protected override async loadData(formCode, id: string) {
    this.httpService.get(`${this.environment.api.application}/${id}`).subscribe(response => {

      this.afterLoad(response.Data ? JSON.parse(response.Data) : response, formCode);
      this.setSubmission(response.Data ? JSON.parse(response.Data) : response, id);
    },
    (e) => {
      this.error(e);
    });
  }

  protected signalPrintoutCompleted(id: string) {
    void this.loadData(null, id).then(() => {
      this.refreshForm.emit({
        form: this.form
      });
    });
  }

}

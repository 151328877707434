export const routesEnum = {
  home: { url: '', label: 'breadcrumbs.home' },
  profile: { url: 'my-profile/form', label: 'breadcrumbs.profile' },
  userList: { url: 'user-list', label: 'breadcrumbs.userList' },
  representationList: { url: 'representation-list', label: 'breadcrumbs.represantationList' },
  form: { url: 'form', label: 'breadcrumbs.recordAdd' },
  classifiers: { url: 'classifiersList', label: 'breadcrumbs.recordList' },
  domains: { url: 'domainsList', label: 'breadcrumbs.classifierList' },
  institutionList: { url: 'institutionList', label: 'breadcrumbs.institutionList' },
  registerList: { url: 'registerList', label: 'breadcrumbs.registerList' },
  registerHistoryList: { url: 'registerHistoryList', label: 'breadcrumbs.registerHistoryList' },
  roleList: { url: 'roleList', label: 'breadcrumbs.roleList' },
  accountList: { url: 'accountList', label: 'breadcrumbs.accountList'},
  representativeList: { url: 'representativeList', label: 'breadcrumbs.representativeList'},
  registerFileList: { url: 'registerFileList', label: 'breadcrumbs.registerFilesList' },
  languageList: { url: 'languageList', label: 'breadcrumbs.languageList' },
  translationList: { url: 'translationList', label: 'breadcrumbs.translationList' },
  idGenerationConfigsList: { url: 'idGenerationConfigsList', label: 'breadcrumbs.idGenerationConfigsList' },
  messageTemplatesList: { url: 'messageTemplatesList', label: 'breadcrumbs.messageTemplatesList' },
  messageTemplatesEditor: { url: 'messageTemplatesEditor', label: 'breadcrumbs.messageTemplatesEditor' },
  messageTemplatesViewer: { url: 'messageTemplatesViewer', label: 'breadcrumbs.messageTemplatesViewer' },
  notificationViewer: { url: 'notificationViewer', label: 'breadcrumbs.notificationViewer' },
  pushNotificationList: { url: 'pushNotificationList', label: 'breadcrumbs.pushNotificationList' },
  eventNotificationConfig: { url: 'eventNotificationConfig', label: 'breadcrumbs.eventNotificationConfig' },
  register: { url: 'register', label: 'breadcrumbs.register' },
  registerServicesList: { url: 'servicesList', label: 'breadcrumbs.registerServicesList' },
  registerMyTaskList: { url: 'registerMyTaskList', label: 'breadcrumbs.registerMyTaskList' },
  registerUnassignedTaskList: { url: 'registerUnassignedTaskList', label: 'breadcrumbs.registerUnassignedTaskList' },
  registerTaskList: { url: 'registerTaskList', label: 'breadcrumbs.registerTaskList' },
  registerTaskListUpdated: { url: '/register/taskList', label: 'breadcrumbs.registerTaskList' },
  registerRegistersList: { url: 'registersList', label: 'breadcrumbs.registerRegistersList' },
  registerApplicationsList: { url: 'applicationsList', label: 'breadcrumbs.registerApplicationsList' },
  eventNotificationConfigList: { url: 'eventNotificationConfigList', label: 'breadcrumbs.eventNotificationConfigList' },
  myOffice: { url: 'myOffice', label: 'breadcrumbs.myOffice' },
  submittedApplications: { url: 'submittedApplications', label: 'breadcrumbs.submittedApplications' },
  incompleteApplications: { url: 'incompleteApplications', label: 'breadcrumbs.incompleteApplications' },
  registerServiceDetails: { url: 'registerServiceDetails', label: 'breadcrumbs.registerServiceDetails' },
  registerServiceList: { url: 'registerServiceList', label: 'breadcrumbs.registerServiceList' },
  cronJobConfiguration: { url: 'cronJobConfigurationList', label: 'breadcrumbs.cronJobConfigurationList' },
  registerFunctionalityList: { url: 'registerFunctionalityList', label: 'breadcrumbs.registerFunctionalityList' },
  registerFunctionality: { url: 'functionality', label: 'breadcrumbs.registerFunctionality' },
  reassign: { url: 'reassign', label: 'breadcrumbs.reassign' },
  myOfficeRegistersList: { url: 'myRegistersList', label: 'breadcrumbs.myOfficeRegistersList' },
  myOfficeRepresentativesList: { url: 'myRepresentativesList', label: 'breadcrumbs.myOfficeRepresentativesList' },
  externalClientsList: { url: 'externalClientsList', label: 'breadcrumbs.externalKeycloakClient' },
  printoutQRCodeVerification: { url: 'printoutQRCodeVerification', label: 'breadcrumbs.printoutQRCodeVerification' },
  accountTypeList: { url: 'accountTypeList', label: 'breadcrumbs.accountTypeList' },
  registerService: {url: 'registerService', label:'breadcrumbs.registerService'},
  registerServiceGeneral: {url: 'registerServiceGeneral', label:'breadcrumbs.registerServiceGeneral'},
  registerServiceWorkflow: {url: 'registerServiceWorkflow', label:'breadcrumbs.registerServiceWorkflow'},
  registerServiceConfiguration: {url: 'registerServiceConfiguration', label:'breadcrumbs.registerServiceConfiguration'},
  registerServiceWorkflowConfiguration: {url: 'registerServiceWorkflowConfiguration', label:'breadcrumbs.registerServiceWorkflowConfiguration'},
  registerServiceRegistrationObjectLoadCleanUpConfiguration: {url: 'registerServiceRegistrationObjectLoadCleanUpConfiguration', label:'breadcrumbs.registerServiceRegistrationObjectLoadCleanUpConfiguration'},
  smsProvidersConfigList: { url: 'smsProvidersConfigList', label: 'breadcrumbs.smsProviderConfigList' },
  nameSimilarity: { url: 'nameSimilarity', label: 'breadcrumbs.nameSimilarity' },
};

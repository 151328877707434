<ng-template ngIf="!isLoading;">
    <div class="thumbnail">
        <img #thumbnail class="thumbnail-register-services thumbnail-text">
        <div class="thumbnail-tooltip"
              mat-raised-button
              matTooltip="{{tooltipText}}"
              matTooltipPosition="above">

              <i [ngClass]="faIcon"
                 (mouseover)="faIcon='fas fa-question-circle thumbnail-question'"
                 (mouseout)="faIcon='far fa-question-circle thumbnail-question'"></i>

            </div>
        <span class="thumbnail-text-center">{{registerName | translate }}</span>
        <button class="cp-btn cp-btn--primary cp-btn--wider" (click)="onClick()">{{buttonText}}</button>
    </div>
</ng-template>
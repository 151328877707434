<!-- Desktop -->
<div class="d-none d-lg-block">
    <div *ngIf="!isObjectEmpty(data)" class="cp-sidebar__submenu-wrapper">
        <!-- <span role="button" class="c-icon c-icon-grey c-icon-20 urp-icon-chevron-left-1" (click)="close()"></span> -->
        <!-- <h6>{{title | translate}}</h6> -->
        <div class="display-table-100">
            <div class="table-cell-align-vertical-midle">
                <span role="button" (click)="close()" class="c-icon-grey urp-icon urp-icon-chevron-left" title="{{'pages.common.list.closeFilterButton' | translate}}" alt="close"></span>
            </div>
            <div class="table-cell-align-vertical-midle">
                <h6 class="cp-mb-0">{{title | translate}}</h6>
            </div>
        </div>
        
        <ul class="cp-sidebar__submenu sidebar-menu1">
            <li *ngFor="let childItem of children" class="cp-sidebar__item" [class.active]="childItem['activeMenu']">
                <a translate class="nav-link my-1 d-flex flex-row" (click)="clickMenu(childItem)" routerLink="{{childItem.path}}" title="{{childItem.label | translate}}">
                    <span class="collapseDisappear cp-ps-30" translate>{{childItem.label}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>


import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormatEnum } from '../enumerators/dateTimeFormat.enum';

@Pipe({
  name: 'formatDatePipe',
  pure: true
})
export class FormatDatePipe implements PipeTransform {

    format = DateTimeFormatEnum.DATE_TIME;
    constructor(private datePipe: DatePipe) {
    }

    transform(date: any, format?: any, args?: any): any {
      if (format) {
        return this.datePipe.transform(date, format);
      }
      return this.datePipe.transform(date, this.format);
    }
}

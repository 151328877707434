import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ViewContainerRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalStackService } from '../../services/modal-service/modal-stack.service';

@Component({
  selector: 'app-session-modal',
  templateUrl: './session-modal.component.html',
  styleUrls: ['./session-modal.component.scss']
})
export class SessionModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ModalDirective) modal: ModalDirective;

  @Input() show = false;
  @Input() showBackdrop = true;
  @Input() ignoreBackdrop = true;
  @Input() showExitButton = true;
  @Input() keyboard = true;
  @Input() modalClass = 'modal-md';

  // tslint:disable:no-output-on-prefix
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onShown: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onHidden: EventEmitter<any> = new EventEmitter();
  // tslint:enable:no-output-on-prefix
  @Output() modalLoadedEvent: EventEmitter<any> = new EventEmitter();
  @Output() exitEvent: EventEmitter<any> = new EventEmitter();

  readonly debug = false;

  constructor(private elRef: ElementRef, private modalService: ModalStackService) { }
  ngOnInit(): void {
    // Move modal to bottom of document
    this.pushModalToTop();
    // Get modal parent name for easier debugging
  }

  ngAfterViewInit(): void {
    // Settings for modal
    this.modal.config.backdrop = this.showBackdrop;
    this.modal.config.show = this.show;
    this.modal.config.ignoreBackdropClick = this.ignoreBackdrop;
    this.modal.config.keyboard = this.keyboard;
    this.modalLoadedEvent.emit(true);
  }

  ngOnDestroy(): void {
    // We moved the element to the bottom of the document
    // If we dont remove it its going to remain as trash
    this.elRef.nativeElement.parentNode.removeChild(this.elRef.nativeElement);
  }

  showModal(): void {
    this.modalService.pushModal(this);
    this.modal.show();
    this.onShow.emit();
  }

  showAnimationStart() {
  }

  showAnimationFinished() {
    this.onShown.emit();
  }

  hideModal(): void {
    this.modalService.popModal();
    this.modal.hide();
    this.onHide.emit();
  }

  hideAnimationStart() {
  }

  hideAnimationFinished() {
    this.onHidden.emit();
  }

  toggleModal(): void {
    this.modal.toggle();
  }

  exitButtonClick(): void {
    this.hideModal();
    this.exitEvent.emit(true);
  }

  isOpen(): boolean {
    return this.modal.isShown;
  }

  pushModalToTop(): void {
    try {
      document.body.appendChild(this.elRef.nativeElement);
    } catch (error) {
      console.log('Failed to append modal to body.');
      console.log(error);
    }
  }

  keyAction(event, place) {
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey && place === 'start') {
          event.preventDefault();
          break;
        }
        if (place === 'end') {
          const startDiv = <HTMLElement>this.elRef.nativeElement.querySelector('.start');
          startDiv.focus();
        }
        break;
    }
  }
}

<div *ngIf="registerServiceDTO">
    <div class="w-100 c-picture d-flex flex-row" [ngStyle]="{'background-position': 'right', 'background-image':'url(' + inboxImage + ')'}">
        <div class="w-100 px-2">
            <app-breadcrumb [isHeader]="false"></app-breadcrumb>
            <div class="c-max-width-646px  my-5 mx-auto rounded-1 input-group">               
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-md-row c-bg-off-grey">
        <div class="w-100 px-2">
            <div class="c-max-width-646px mx-auto h-100 py-4">
                <div class="mt-4">
                <div class="d-flex align-items-center">
                    <button class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center mb-2 border-0" (click)="goBack()">
                    <span class="fa-solid fa-arrow-left fa-xl"></span>
                    </button>
                    <h4>{{registerServiceDTO.Name | translate}}</h4>
                </div>
                <div class="mt-3 bg-white rounded-1 d-flex flex-column overflow-hidden w-100">
                    <div class="py-3 px-4 d-flex flex-column flex-md-row justify-content-md-between border-bottom">
                        <div>
                            <h5 translate>pages.services.start_filling</h5>
                            <span class="d-block" translate>pages.services.order_for</span>
                        </div>
                        <div>
                            <button translate (click)="startProcess()" type="button" class="btn btn-primary" title="Start" alt="Start">pages.services.apply</button>
                        </div>
                    </div>
                    <div *ngIf="registerServiceDTO.videoGuidelines || registerServiceDTO.serviceDescriptionLong" class="py-3 px-4 d-block justify-content-md-between border-bottom">
                        <h6>{{registerServiceDTO.guidelines}}</h6>
                        <span *ngIf="registerServiceDTO.videoGuidelines" class="mt-3" >
                            <iframe class="d-block w-100" title="Video Guidelines" width="" height="315" [src]='safeURL' allow="fullscreen"></iframe>
                        </span>
                        <p class="mt-2">{{registerServiceDTO.serviceDescriptionLong}}</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

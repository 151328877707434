import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-filter-buttons',
    templateUrl: 'filter-buttons.component.html'
})
export class FilterButtonsComponent {

    @Input() title: string;
    @Output() clickClearFilter = new EventEmitter();
    @Output() clickCloseFilter = new EventEmitter();
   
    onClearFilter(): void {
        this.clickClearFilter.emit();
    }

    onCloseFilter(): void {
        this.clickCloseFilter.emit();
    }

}

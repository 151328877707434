import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormsService } from "projects/urp/src/app/services/forms.service/forms.service";
import { BaseFormComponent } from "../../../shared/base-form/base-form.component";
import { Location } from '@angular/common';
import { ToastrServiceExt } from "projects/urp/src/app/services/toastr.service/toastr.service";
import { HttpService } from "projects/urp/src/app/services/http-service/http.service";
import { HelperService } from "projects/urp/src/app/services/helper.service/helper.service";
import { KeycloakService } from "keycloak-angular";
import { BsModalService } from "ngx-bootstrap/modal";
import { LocalStorageService } from "projects/urp/src/app/services/local-storage.service/local-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { MenuService } from "projects/urp/src/app/services/menu.service/menu.service";
import { APP_CONFIG } from "projects/urp/src/app/utility/tokens";
import { IConfig } from "projects/urp/src/app/models/config.interface";

@Component({
    selector: 'app-representative-form',
    templateUrl: '../../../shared/base-form/base-form.component.html',
    styleUrls: ['../../../shared/base-form/base-form.component.scss']
})
export class RepresentativeFormComponent extends BaseFormComponent {
    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected formsService: FormsService,
        protected location: Location,
        protected toastrService: ToastrServiceExt,
        protected httpService: HttpService,
        protected helper: HelperService,
        protected readonly keycloak: KeycloakService,
        protected modal: BsModalService,
        protected storage: LocalStorageService,
        protected injector: Injector,
        protected translateService: TranslateService,
        protected menuService: MenuService,
        @Inject(APP_CONFIG) protected readonly environment: IConfig) {
        super(route, router, formsService, location, toastrService, httpService, helper, keycloak, modal, storage, translateService, injector, environment);
    }

    protected override save(formData: any): void {
        formData.data.FormId = this.formId;
        formData.data.FormCode = this.formCode;
        const data = Object.assign({}, formData.data, this.params);

        this.httpService.post(`${this.environment.api.umRepresentatives}/SaveInternal`, data).subscribe(res => {
            this.successSave(res, this.formCode);
        }, (e) => {
            this.error(e);
        });

        this.clickSuccess.emit(formData.instance);
    }

    protected override async loadData(formCode, id: string) {
        try {
          const res = await this.httpService.get(`${this.environment.api.umRepresentatives}/${id}`).toPromise();
          const data = res.Data ? JSON.parse(res.Data) : res;
          //this.afterLoad(data);
          this.setSubmission(data, id);
        } catch (e) {
          this.error(e);
        }
      }
}
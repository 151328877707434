<!-- TODO add separate toaster for warning -->
<div *ngIf="toastPackage.toastType === 'toast-error' || toastPackage.toastType === 'toast-warning'">
    <div *ngIf="!title" class="error">
        <div class="error-top">
            {{message}}
        </div>
    </div>

    <div *ngIf="title" class="error error--two-line-no-details">
        <div class="error-top">
            {{message}}
            <span class="error-nr">{{title}}</span>
        </div>
    </div>
</div>


<div *ngIf="toastPackage.toastType === 'toast-success' || toastPackage.toastType === 'toast-info'">
    <div *ngIf="!title" class="notification">
        <div class="notification-top">
            {{message}}
        </div>
    </div>
    
    <div *ngIf="title"  class="notification notification--two-line">
        <div class="notification-top">
            {{message}}
            <span class="notification-nr">{{title}}</span>
        </div>
    </div>
</div>

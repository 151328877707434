import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  public clickEvent: EventEmitter<Record<string, unknown>> = new EventEmitter();

  title?: string;
  message?: string;
  okButtonName?: string;
  cancelButtonName?: string;
  
  isConfirmation: boolean;
  isDeletion: boolean;
  isChecked: boolean;
  showCheckbox: boolean;
  
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.isConfirmation = false;
    this.isDeletion = false;
    this.showCheckbox = false;
    this.isChecked = false;
  }

  confirm(): void {
    this.modalRef.hide();
    this.clickEvent.emit({ okEvent: true });
  }
 
  decline(): void {
    this.modalRef.hide();
  }

}

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { routesEnum } from '../enumerators/routesEnum';
import { formEnum } from '../enumerators/Forms.enum';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(private keycloak: KeycloakService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(() => { /* do nothing. */ },
            (err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }                    
                    void this.keycloak.isLoggedIn().then(isLoggedIn => {
                        if (!isLoggedIn) {
                            window.location.href = this.keycloak.getKeycloakInstance().createLoginUrl();
                        }                       
                    });
                }
            }));
    }
}
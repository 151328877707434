import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs/internal/Observable';
import { ContextService } from '../context.service/context.service';
import { HelperService } from '../helper.service/helper.service';
import { IService } from '../service.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpService implements IService {

  representationId: Guid;

  constructor(private http: HttpClient,
    private helper: HelperService,
    private context: ContextService) {
    this.context.representationId.subscribe(id => {      
      this.representationId = id;
    });
  }

  getQueryParams(filter: any): string {
    filter = Object.entries(filter).reduce((result, [key, value]) => {
      if (value) {
        result[key] = value;
      }

      return result;
    }, {});

    const httpParams = new HttpParams({ fromObject: filter });
    return httpParams.toString();
  }

  getList(url: string, filter: any): Observable<any> {
    const param = this.getQueryParams(filter);
    const fullUrl = `${url}?${param}`;
    const headers = this.getHeaders();

    return this.http.get(fullUrl, { headers: headers });
  }

  post(url: string, payload: any, httpOptions: any = null): Observable<any> {
    let headers = this.getHeaders();

    if (httpOptions && httpOptions.headers) {
      httpOptions.headers.forEach(element => {
        headers = headers.set(element, httpOptions.headers.getAll(element));
      });
    }

    return this.http.post(url, payload, {
      headers: headers,
      responseType: httpOptions?.responseType
    });
  }

  get(url: string, httpOptions: any = null): Observable<any> {
    let headers = this.getHeaders();

    if (httpOptions && httpOptions.headers) {
      httpOptions.headers.forEach(element => {
        headers = headers.set(element, httpOptions.headers.getAll(element));
      });
    }

    return this.http.get(url, {
      headers: headers,
      responseType: httpOptions?.responseType
    });
  }

  delete(url: string): Observable<any> {
    const headers = this.getHeaders();

    return this.http.delete(url, { headers: headers });
  }

  // atrodo Keycloak servisas perraso headerius todel neina prideti iprastiniu budu, nei per http-interceptoriu
  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const currentSystemLang = this.helper.getCurrentSystemLanguage();
    // didnt work before, is actually needed?
    //headers = headers.set('Accept-Language', currentSystemLang);
    
    if (this.representationId) {
      headers = headers.set('RepresentationId', this.representationId.toString());
    }

    return headers;
  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubSystem } from '../../../enumerators/classifier.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-office-registration-objects-list',
  templateUrl: './my-office-registration-objects-list.component.html',
  styleUrls: ['./my-office-registration-objects-list.component.scss']
})
export class MyOfficeRegistrationObjectsListComponent implements OnInit {
  module: SubSystem = SubSystem.REG;
  MyOfficeRegisterListForm: string;

  constructor(protected activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.MyOfficeRegisterListForm = this.activatedRoute.snapshot.queryParams.MyOfficeRegisterListForm;
  }

}

import { KeycloakService } from 'keycloak-angular';
import { AppConfigService } from '../services/app-config.service';

const onLoadValue = <const>'check-sso';

export function initializeKeycloak(keycloak: KeycloakService, appConfigSvc: AppConfigService): () => void {
    return () => {
      return appConfigSvc.loadConfig().then(() => {
        const config = appConfigSvc.getConfig();
        const keycloakConfig = { config: config.keycloak, initOptions: {
          onLoad: onLoadValue,
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html'
        } }
        return keycloak.init(keycloakConfig);
      })
    }
  }
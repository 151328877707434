import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrServiceExt {

    ERROR_PREFIX = "exception";
    DEFAULT_ERROR_DOMAIN = "Generic";
    DEFAULT_ERROR_TYPE = "Error";

    constructor(private toastr: ToastrService,
                private translateService: TranslateService) {}

    public error(label?: string): void {
        const key = label ?? `${this.ERROR_PREFIX}.${this.DEFAULT_ERROR_DOMAIN}.${this.DEFAULT_ERROR_TYPE}`;

        this.translateService.get(key).subscribe(translation => {
            this.toastr.error(translation);
        }); 

    }

    public serverError(response: any): void {

        let key = `${this.ERROR_PREFIX}.${this.DEFAULT_ERROR_DOMAIN}.${this.DEFAULT_ERROR_TYPE}`;

        let error = response.error;

        if (error) {
            if (error.data?.exceptionTranslationKey) {
                key = error.data.exceptionTranslationKey;
            } else {
                // domain contains full namespace path when only the last part is needed
                let errorDomain = error.domain?.split('.').pop();
                key = `${this.ERROR_PREFIX}.${errorDomain ?? this.DEFAULT_ERROR_DOMAIN}.${error.exception ?? this.DEFAULT_ERROR_TYPE}`;
            }
        }

        this.translateService.get(key).subscribe(translation => {
            this.toastr.error(translation);
        });   
    }

    public success(message?: string): void {
        this.toastr.success(message);
    }

    public successTranslatable(message?: string): void {
        this.translateService.get(message).subscribe(translation => {
            this.toastr.success(translation);
        }); 
    }
}
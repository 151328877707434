import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { DefaultParams } from 'projects/urp/src/app/models/filter.model';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { AppConfigService } from 'projects/urp/src/app/services/app-config.service';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-language',
  templateUrl: './language-list.component.html'
})
export class LanguageListComponent extends BaseListComponent {
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');

  listUrl = routesEnum.languageList.url;
  formEnum = formEnum.languageForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Code', '', 'flag'),
    new TableColumn('Code', 'pages.language.list.data.shortName'),
    new TableColumn('Name', 'pages.language.list.data.name'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              protected route: ActivatedRoute,
              private appConfigService: AppConfigService,
              protected helperService: HelperService) { 
    super(toastrService, modal, router, route, keycloak, location, httpService); 
    this.apiUrl = this.appConfigService.getConfig().api.language;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.Language);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }

}

import { Components } from "@formio/angular";
const Radio = Components.components.radio;

export default class RadioComponentExtend extends Radio {

  loadItems(url, search, headers, options, method, body) {
    // Ensure we have a method and remove any body if method is get
    method = method || 'GET';
    if (method.toUpperCase() === 'GET') {
      body = null;
    }

    // Set ignoreCache if it is
    options.ignoreCache = this.component.ignoreCache;
    // Make the request.
    options.header = headers; 
    if (this.shouldLoad) {
      this.loading = true;
      Formio.makeRequest(this.options.formio, 'select', url, method, body, options)
        .then((response) => {
          this.loading = false;
          this.error = null;
          this.setItems(response);
          // TODO after formio.js update should be override shouldLoad function - not shouldLoad is parameter
          if (!this.readOnly) {
            this.shouldLoad = false;
          }
          this.redraw();
        })
        .catch((err) => {
          this.handleLoadingError(err);
        });
    }
  }

}
Components.addComponent("radio", RadioComponentExtend);

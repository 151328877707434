<app-breadcrumb [isHeader]="true"></app-breadcrumb>
<div class="container-fluid page-wrapper main-wrapper mt-5 c-max-width-646px">
    <div class="wrap">
        <div class="formio">
            <div class="formio-component formio-component-form  formio-component-label-hidden">
                <div class="flex-row d-flex formio-component formio-component-columns formio-component-columns  formio-component-label-hidden mb-3">
                        <div ref="component" class="form-group has-feedback formio-component formio-component-button formio-component-rodykle bg bg-transparent icon-color-primary icon-center p-0 border-0 form-group">
                            <button lang="en" class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0" type="button" [routerLink]="'/pushNotificationList'">
                                <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
                            </button>
                            <h3>&nbsp; {{'pages.notification.systemMessage' | translate}}</h3>   
                        </div>
                </div>
                <div class="formio-component formio-component-panel formio-component-panel">
                    <div class="mb-2 card border">
                        <div class="card-header bg-default">
                            <span class="mb-0 card-title">  
                                {{notification.Title}}
                            </span>
                        </div>
                        <div class="card-body">
                            <div [innerHTML]="notificationBody"></div>
                        
                        </div>    
                    </div> 
                </div>        
                <div class="formio-component formio-component-panel formio-component-panel">
                    <div class="mb-2 card border">
                        <div class="card-header bg-default">
                            <span class="mb-0 card-title">
                                <i data-title="Collapse Panel" class="formio-collapse-icon fa fa-minus-square-o text-muted"></i>  
                                {{'pages.notification.info' | translate}}
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="form-group has-feedback formio-component formio-component-textfield">
                                <label class="col-form-label">{{'pages.notification.form.firstReaderFullName' | translate}}</label>
                                    <div> 
                                        <input [(ngModel)]="notification.FirstReaderFullName" disabled="disabled" class="form-control" type="text">
                                    </div>
                            </div>
                            <div class="form-group has-feedback formio-component formio-component-textfield">
                                <label class="col-form-label">{{"pages.notification.form.firstNotificationReadDateTime" | translate}}</label>
                                <div> 
                                    <input [(ngModel)]="notificationDate" disabled="disabled" class="form-control" type="text">
                                </div>
                            </div>
                        </div>    
                    </div> 
                </div>
            </div>
        </div>
    </div>
</div>


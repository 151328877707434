import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { MatTableDataSource } from '@angular/material/table';
import { EventNotificationConfig } from 'projects/urp/src/app/models/eventNotificationConfig.model';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { Observable } from 'rxjs/Rx';

export interface EventNotificationConfigDataSource {
  MessageTemplateId: string;
  NotificationChannelId: string;
  NotificationChannelName: string;
  AvaiableToUser: boolean;
  AvaiableToEntity: boolean;
}

@Component({
  selector: 'app-event-notification-config',
  templateUrl: './event-notification-config.component.html',
  styleUrls: ['./event-notification-config.component.scss']
})

export class EventNotificationConfigComponent {

  displayedColumns: string[] = ['NotificationChannelName', 'MessageTemplateId', 'AllowedForUser', 'AllowedForEntity'];

  dataSource; 
  model;
  messagesTemplates;
  protected classifierData: any = {};
  InstitutionIds;
  notificationChannels;
  classifiersLoading = true;

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected route: ActivatedRoute,
              protected router: Router,
              private helper: HelperService,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
              
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const id = params.id;
      if(id) {
        Observable.forkJoin([
          this.httpService.get(`${this.environment.api.messageTemplate}/selectSearch`), 
          this.httpService.get(`${this.environment.api.classifier}/selectSearch?ClassifierDomainNaturalIds=NOTIFICATION-CHANNEL&translationLanguage=${this.helper.getCurrentSystemLanguage()}`),
          this.httpService.get(`${this.environment.api.eventNotificationConfigs}/${id}`), 
        ])
        .subscribe(data => {
          const messagesTemplatesResponse = data[0];
          const notificationChannelsResponse = data[1];
          const eventNotificationConfigResponse = data[2];

          this.classifierData.institutions = messagesTemplatesResponse;
          this.notificationChannels = notificationChannelsResponse;
          this.model = eventNotificationConfigResponse as EventNotificationConfig;

          this.classifiersLoading = false;
          this.applyChannelClassifiers();

        }, (err) => {
          this.toastrService.serverError(err);
        });
      }
    });
  }

  applyChannelClassifiers() : void {
    this.model.SubConfigs.forEach(element => {
      let channelName = this.notificationChannels.find(x => x.value === element.NotificationChannelId);
      element.NotificationChannelName = (channelName) ? channelName.label : "";
    });
    this.dataSource = new MatTableDataSource<EventNotificationConfigDataSource>(this.model.SubConfigs);
  }

  onSelectChange(data: any, element: any): void {
    element.MessageTemplateId = data['propertyValue'];
  }


  onSaveEntity() : void {

      const data = Object.assign({}, this.model);
      this.httpService.post(`${this.environment.api.eventNotificationConfigs}`, data).subscribe(res => {
        this.toastrService.success('Saved');
        this.model = res as EventNotificationConfig;
        this.dataSource = new MatTableDataSource<EventNotificationConfigDataSource>(this.model.SubConfigs);
      }, (e) => {
        this.error(e);
      });
  }

  protected error(errormessage: any) {
    this.toastrService.serverError(errormessage);
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IConfig } from "../models/config.interface";

@Injectable()
export class AppConfigService {
    private config: IConfig;
    loaded = false;

    constructor(private http: HttpClient) {}

    async loadConfig(): Promise<void> {
        const data = await this.http.get<IConfig>('assets/config/config.json').toPromise();
        this.config = data;
        this.loaded = true;
    }

    getConfig(): IConfig {
        return this.config;
    }
}
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class LoadingStateService {    
    private loadingState$ = new BehaviorSubject<any>(false);
    activeLoadingState$ = this.loadingState$.asObservable();
    constructor() {}
    setLoadingState(data) {
      this.loadingState$.next(data);
    }    
}
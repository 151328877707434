<div >        
    <div class="card-body">
        <div class="form-group" style="float:right">
            <div class="form-group-actions">
                <a translate class="btn--cust btn--light btn--thin btn--min240"
                    (click)="onSaveEntity()">{{'pages.common.form.saveButton' | translate}}</a>
            </div>
        </div>
    </div>
    <div class="card-body w-9/12">
        <h1>{{'pages.eventNotificationConfig.title' | translate}}</h1>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          
            <ng-container matColumnDef="NotificationChannelName">
              <th mat-header-cell *matHeaderCellDef> {{'pages.eventNotificationConfig.title' | translate}} </th>
              <td mat-cell *matCellDef="let element"> {{element.NotificationChannelName | translate}} </td>
            </ng-container>
          
            <ng-container matColumnDef="MessageTemplateId">
                <th mat-header-cell *matHeaderCellDef> {{'pages.eventNotificationConfig.messageTemplateId' | translate}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <app-select style="background-color: brown;" *ngIf="!classifiersLoading"
                    [parentPropertyName]="'MessageTemplateId'"
                    [selectedId]="element.MessageTemplateId"
                    [list]="classifierData.institutions"
                    (selectChange)="onSelectChange($event, element)"> 
                </app-select>
                </td>
            </ng-container>
          
            <ng-container matColumnDef="AllowedForUser">
              <th mat-header-cell *matHeaderCellDef> {{'pages.eventNotificationConfig.allowedForUser' | translate}} </th>
              <td mat-cell *matCellDef="let element">  
                <input class="cp-checkbox" type="checkbox"  [(ngModel)]="element.AllowedForUser">
                <!-- <mat-checkbox [(ngModel)]="element.AllowedForUser"></mat-checkbox> -->
             </td>
            </ng-container>
          
            <ng-container matColumnDef="AllowedForEntity">
              <th mat-header-cell *matHeaderCellDef> {{'pages.eventNotificationConfig.allowedForEntity' | translate}} </th>
              <td mat-cell *matCellDef="let element">
                <input class="cp-checkbox" type="checkbox"  [(ngModel)]="element.AllowedForEntity">  
                <!-- <mat-checkbox [(ngModel)]="element.AllowedForEntity"></mat-checkbox> -->
             </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>          
    </div>
</div>


export class TableColumn {
    id: string;
    label: string;
    type: string;
    classifierTranslationKey: string;
    cssClassColumn: string;

    constructor(id: string, label: string, type: string = null, classifierTranslationKey: string = null, cssClassColumn: string = null) {
        this.id = id;
        this.label = label;
        this.type = type;
        this.classifierTranslationKey = classifierTranslationKey;
        this.cssClassColumn = cssClassColumn;
    }
}
import { Component, Inject, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { LocalStorageService } from 'projects/urp/src/app/services/local-storage.service/local-storage.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { ProcessInstanceDto } from '../../../models/processInstance.model';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { routesEnum } from '../../../enumerators/routesEnum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-object-form',
  templateUrl: '../../shared/base-form/base-form.component.html',
  styleUrls: ['../../shared/base-form/base-form.component.scss'],
})
export class RegistrationObjectHistoryFormComponent extends BaseFormComponent {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected formsService: FormsService,
    protected location: Location,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    protected helper: HelperService,
    protected readonly keycloak: KeycloakService,
    protected modal: BsModalService,
    protected storage: LocalStorageService,
    protected translateService: TranslateService,
    protected injector: Injector,
    @Inject(APP_CONFIG) protected readonly environment: IConfig
  ) {
    super(route, router, formsService, location, toastrService, httpService, helper, keycloak, modal, storage, translateService, injector, environment);
  }

  protected override submition(formData): void {
    this.submission = formData;
    this.submitted = true;
    this.save(formData);
  }

  protected override save(formData: any): void {
  }

  protected override async loadData(formCode, id: string) {
    try {
      const res = await this.httpService.get(`${this.environment.api.registerHistory}/${id}`).toPromise();

      const data = res.Data ? JSON.parse(res.Data) : res;
      this.afterLoad(data, formCode);
      this.setSubmission(data, id);
    } catch (e) {
      this.error(e);
    }
  }
}

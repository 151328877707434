import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingStateService } from '../services/loading-state.service/loading-state.service';
import { CustomHttpHeader } from '../enumerators/common.enum';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  service_count = 0;

  constructor(public loadingStateService: LoadingStateService) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const noSpinner: boolean = req.headers.has(CustomHttpHeader.NoSpinner);
    if (noSpinner) {
      const headers = req.headers.delete(CustomHttpHeader.NoSpinner);
      return next.handle(req.clone({ headers }));
    }
    else {
      this.service_count++;
      this.loadingStateService.setLoadingState(true);
      return next.handle(req).pipe(
        finalize(() => {
          this.service_count--;
          if (this.service_count === 0) {
            this.loadingStateService.setLoadingState(false);
          }
          if (this.service_count < 0) {
            this.service_count = 0;
            this.loadingStateService.setLoadingState(false);
          }
        })
      );
    }
  }
}
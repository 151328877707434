<!-- <app-base-filter [btnAddNewTitle]="'pages.user.list.addNewButton'" (clickCreate)="onCreate()">
</app-base-filter> -->
<app-breadcrumb [isHeader]="true"></app-breadcrumb>
<app-dynamic-list
        [module]="module" 
        [listingMongoId]="listingMongoId"
        [filterHeader]="'pages.userList.title'"
        [actions]="actions"
        [btnAddNewTitle]="'pages.user.list.addNewButton'"
        (create)="onCreate()"
></app-dynamic-list>
<app-breadcrumb [isHeader]="true"></app-breadcrumb>
<div class="container-fluid c-max-width-755px">
    <div class="w-100 d-flex my-4 cp-ps-32">
        <button
            class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0"
            [routerLink]="'/myOffice'">
            <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
        </button>
        <h4 class="my-0 mx-3" translate>pages.myOffice.nameSimilarity.header</h4>
    </div>
    <ng-container>
      <div class="w-100 padding-x-8">
        <div class="my-1 mx-auto rounded-1 input-group">
            <span class="input-group-text bg-white cp-mb-20 border-radius-left-4">
                <div class="c-icon c-i-search float-start me-2 c-icon-darker-grey"></div>
            </span>
            <input id="FilterValue" name="FilterValue" #name class="p-2 form-control border-start-0 shadow-none"
                placeholder="{{'pages.myOffice.nameSimilarity.search' | translate}}"
                (input)="onSearchChange($event.target.value)"  />
        </div>
        <div role="radiogroup" class="horizontal-radio-group form-radio radio mb-4">
          <div *ngFor="let fieldValueGroup of classifierData.FIELD_VALUE_GROUP" class="w-100 padding-x-16" class="radio form-check-inline">
            <div role="radio" *ngIf="fieldValueGroup.value !== fvgDefault">
              <label for="{{'FilterFieldValueGroup-' + fieldValueGroup.value}}" class="form-check-label">
                <input 
                  role="radio"
                  type="radio"
                  #radio
                  id="{{'FilterFieldValueGroup-' + fieldValueGroup.value}}"
                  class="form-check-input"
                  value="{{fieldValueGroup.value}}"
                  [checked]="isChecked(fieldValueGroup.value)"
                  (click)="onSearchLanguageChange($event.target)"
                >
                <span translate>
                  {{fieldValueGroup.label}}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="loading" class="container-fluid">
        <app-loader type="list"></app-loader>
      </div>
      <div *ngIf="!loading">
        <app-data-table
          *ngIf="!classifiersLoading"
          [tableData]="tableData"
          [tableColumns]="tableColumns"
          [listActions]="actions"
          [classifiers]="classifierData"
          (edit)="onEdit($event)"
          (view)="onView($event)"
          (delete)="onDelete($event)"
          (toList)="onToList($event)"
          (sortChange)="onSortChange($event)"
        >
        </app-data-table>
      </div>
        <app-pagging
            [page]="searchData.Page"
            [pageSize]="searchData.PageSize"
            [total]="totalItem"
            (pageSizeChange)="onPageSizeChange($event)"
            (pageChange)="onPageChange($event)"
        ></app-pagging>
    </ng-container>
</div>

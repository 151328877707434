/* eslint @typescript-eslint/no-explicit-any: 0, @typescript-eslint/explicit-module-boundary-types: 0 */
import { AfterViewInit, Component, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsService } from '../../../services/forms.service/forms.service';
import { Location } from '@angular/common';
import { FormatDatePipe } from '../../../pipes/format-date.pipe';
import { HttpService } from '../../../services/http-service/http.service';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { HelperService } from '../../../services/helper.service/helper.service';
import { routesEnum } from '../../../enumerators/routesEnum';
import { KeycloakService } from 'keycloak-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../../shared/modal/modal.component';
import { LocalStorageService } from '../../../services/local-storage.service/local-storage.service';
import { Observable, Subscription } from 'rxjs-compat';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';
import { first, map } from 'rxjs/operators';
import { DeactivationGuarded } from '../../../utility/navigation.guard';
import { PageLeaveModalComponent } from '../page-leave-modal/page-leave-modal.component';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';

interface ModalResponse {
  okEvent: boolean;
}

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss'],
  providers: [FormatDatePipe]
})

export class BaseFormComponent implements OnInit, OnDestroy, DeactivationGuarded {
  form: any;
  submission: any;
  formId: string;
  formCode: string;
  formTitle: string;
  formVersionNo: string;
  readOnly = false;
  show = false;
  submitted = false;
  params: any;
  isFirstLoad = true;
  ignoreSafetyNavigationModal = false;
  modalRef?: BsModalRef;
  leavePageModalRef?: BsModalRef;
  options: any = {};
  apiController: string;
  protected subscription = new Subscription();
  isFormInRenderer = true;
  language = new EventEmitter<any>();
  refreshForm = new EventEmitter();
  loading = false;
  rendered = true;
  isDeleteButtonPressed = false;

  @ViewChild('formio') formioComponent: FormioComponent;

  @Output() clickSuccess = new EventEmitter<Record<string, unknown>>();
  @Output() readonly = new EventEmitter<Record<string, unknown>>();

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected formsService: FormsService,
    protected location: Location,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    protected helper: HelperService,
    protected readonly keycloak: KeycloakService,
    protected modal: BsModalService,
    protected storage: LocalStorageService,
    protected translateService: TranslateService,
    protected injector: Injector,
    @Inject(APP_CONFIG) protected readonly environment: IConfig) {
    this.loading = true;
    this.form = { display: 'form', components: [] };
    this.getOptions();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        if (this.formioComponent)
          this.addLanguage(this.getCurrentLanguage());
      })
    );

    this.activatedRoute.queryParams.subscribe(params => {
      this.submitted = false;
      const formId = params.FormId;
      const formCode = params.FormCode;
      const id = params.Id;
      this.params = params;
      this.readOnly = params.readOnly;
      if (formId) {
        this.getFormByIdAndLoad(formId, id);
      }
      else if (formCode) {
        this.formsService.getLastFormByCode(formCode).subscribe(res => {
          res = res[0];
          void this.setFormDataAndLoad(res, id);
        });
      }
      else if (id) {
        void this.loadDataBeforeForm(id);
      }
      else if (this.formCode) {
        this.formsService.getLastFormByCode(this.formCode).subscribe(res => {
          res = res[0];
          void this.setFormDataAndLoad(res, id);
        });
      }
    });
    if (this.activatedRoute.snapshot.data['apiController']) {
      this.apiController = this.injector.get<string>(this.activatedRoute.snapshot.data['apiController']);
    }

  }

  protected getFormByIdAndLoad(formId: any, id: any) {
    this.formsService.getFormById(formId).subscribe(res => {
      void this.setFormDataAndLoad(res, id);
    });
  }

  protected async setFormDataAndLoad(res: any, id: any): Promise<void> {
    this.form.components = res.components;
    this.form.display = res.display;
    this.formCode = res.code;
    this.formTitle = res.title;
    this.formVersionNo = res.versionId;
    this.formId = res._id;
    if (id) {
      await this.loadData(res.code, id);
    } else {
      this.submitted = true;
    }
    this.refreshForm.emit({
      form: this.form
    });
  }

  protected async loadDataBeforeForm(id): Promise<void> {
    await this.loadData(null, id);
    this.formsService.getLastFormByCode(this.submission.data.FormCode).subscribe(res => {
      const result: any = res[0];
      this.form.components = result.components;
      this.form.display = result.display;
      this.formCode = result.code;
      this.formTitle = result.title;
      this.formVersionNo = result.versionId;
      this.formId = result._id;
      this.refreshForm.emit({
        form: this.form
      });
    });
  }

  back(): void {
    this.location.back();
  }

  // TODO nenaudojama
  resetForm(): void {
    this.readOnly = false;
  }

  // TODO reiks keist
  protected submition(formData): void {
    this.submission = formData;
    this.submitted = true;
    this.save(formData);
  }

  protected customEvent(event: any): void {
    let registrationNumber;
    let registerId;
    switch (event.type) {
      case 'Back':
        this.back();
        break;
      case 'File_History':
        registrationNumber = event.data.RegistrationObjectInfo?.data?.RegistrationObjectNumber || event.data.RegistrationObjectInfo?.RegistrationObjectNumber;
        registerId = event.data.RegistrationObjectInfo?.data?.RegisterId || event.data.RegistrationObjectInfo?.RegisterId;
        void this.router.navigate([`../../${routesEnum.registerFileList.url}`], { relativeTo: this.activatedRoute, queryParams: { RegisterId: registerId, RegistrationNumberExact: registrationNumber } });
        break;
      case 'Register_History':
        registrationNumber = event.data.RegistrationObjectInfo?.data?.RegistrationObjectNumber || event.data.RegistrationObjectInfo?.RegistrationObjectNumber;
        registerId = event.data.RegistrationObjectInfo?.data?.RegisterId || event.data.RegistrationObjectInfo?.RegisterId;
        void this.router.navigate([`../../${routesEnum.registerHistoryList.url}`], { relativeTo: this.activatedRoute, queryParams: { RegisterId: registerId, RegistrationNumberExact: registrationNumber } });
        break;
      case 'Unclaim':
        this.httpService.post(`${this.environment.api.tasks}/unclaim/${String(this.params.Id)}`, {}).subscribe(() => {
          void this.router.navigate([`../../${routesEnum.registerMyTaskList.url}`], { relativeTo: this.activatedRoute, queryParams: { RegisterId: this.params.RegisterId, RegistrationNumberExact: registrationNumber } });
        }, (err) => {
          this.toastrService.error(err);
        });
        break;
      case 'Delete':
        this.openConfirmationModal('confirmationTitles.deleteApplicationTitle', 'confirmationMessages.deleteMessage', false, true, true);

        this.modalRef.content.clickEvent.subscribe(userConfirmed => {
          if (userConfirmed) {
            this.isDeleteButtonPressed = true;
            this.submission.data.Action = event.type;
            this.submitted = true;
            this.save(this.submission);
          }
        });
        break;

      default:
        this.submission.data.Action = event.type;
        this.submitted = true;
        this.save(this.submission);
        break;
    }
  }

  protected save(formData: any): void {
    if (this.apiController) {
      formData.data.FormId = this.formId;
      formData.data.FormCode = this.formCode;
      const data = Object.assign({}, formData.data, this.params);
      this.httpService.post(this.apiController, data).subscribe(res => {
        this.successSave(res, this.formCode);
      }, (e) => {
        this.error(e);
      });
      this.clickSuccess.emit(formData.instance);
      this.refreshForm.emit({
        form: this.form
      });
    }
  }

  close(formData): void {
    this.location.back();
    this.clickSuccess.emit(formData.instance);
  }

  // TODO nenaudojama
  validate(formData) {
    this.toastrService.success('Forma validuota');
    this.clickSuccess.emit(formData.instance);
  }

  // TODO nenaudojama
  reset(formData: any) {
    this.toastrService.success('Forma atstatyta');
    this.clickSuccess.emit(formData.instance);
  }

  protected afterLoad(response: any, formCode: string): void {
    // do nothing
  }

  protected async loadData(formCode, id: string) {
    try {
      const res = await this.httpService.get(`${this.apiController}/${id}`).toPromise();

      const data = res.Data ? JSON.parse(res.Data) : res;
      this.afterLoad(data, formCode);
      this.setSubmission(data, id);
    } catch (e) {
      this.error(e);
    }
  }

  protected setSubmission(res: any, id?: string) {
    if (id) {
      res.Id = id;
    }
    this.submission = { data: res };
    this.submitted = true;
  }

  protected error(errormessage: any) {
    this.toastrService.serverError(errormessage);
    this.submitted = true;
  }

  protected successSave(response?: any, formCode?: string, formId?: string) {
    this.submitted = true;
    this.softSave(response, formCode, formId);
  }

  protected softSave(response?: any, formCode?: string, formId?: string) {
    this.setQueryParams(response, formCode, formId);

    if (this.isDeleteButtonPressed) {
      this.toastrService.successTranslatable('pages.common.form.successDeleteMessage');
    } else {
      this.toastrService.successTranslatable('pages.common.form.successSaveMessage');
    }
  }

  protected setQueryParams(response: any, formCode?: string, formId?: string) {
    this.afterLoad(response.Data ? JSON.parse(response.Data) : response, formCode);
    this.setSubmission(response.Data ? JSON.parse(response.Data) : response, response.Id);

    void this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: Object.assign({}, this.params, { FormCode: formCode || this.formCode, Id: response.Id, FormId: formId || this.formId, RegisterId: this.params.RegisterId }),
      replaceUrl: true
    });
  }

  openConfirmationModal(title: string, message: string, showCheckbox = false, isDeletion = false, isConfirmation = false): void {
    this.modalRef = this.modal.show(ModalComponent);
    this.modalRef.content.okButtonName = 'pages.common.form.okButton';
    this.modalRef.content.cancelButtonName = 'pages.common.form.cancelButton';
    this.modalRef.content.title = title;
    this.modalRef.content.message = message;
    this.modalRef.content.isDeletion = isDeletion;
    this.modalRef.content.isConfirmation = isConfirmation;
    this.modalRef.content.showCheckbox = showCheckbox;
  }

  openLeavePageModal(message: string): void {
    this.leavePageModalRef = this.modal.show(PageLeaveModalComponent);
    this.leavePageModalRef.content.okButtonName = 'pages.common.form.okButton';
    this.leavePageModalRef.content.cancelButtonName = 'pages.common.form.cancelButton';
    this.leavePageModalRef.content.message = message;
  }

  getCurrentLanguage(): string {
    return String(this.storage.retrieve(this.storage.KEY_SYSTEM_LANGUAGE));
  }

  getOptions(): void {
    const languages = this.storage.retrieve(this.storage.KEY_LANGUAGE_LIST);
    this.language.emit(this.getCurrentLanguage().toLowerCase());
    this.options = {
      template: 'bootstrap5',
      disableAlerts: true,
      noAlerts: true,
      viewAsHtml: true,
      language: this.getCurrentLanguage().toLowerCase(),
      i18n: {
        //[this.getCurrentLanguage().toLowerCase()]: this.storage.retrieve(`translations_${this.getCurrentLanguage()}`),
      }
    };
    for (const i in languages) {
      this.options.i18n[languages[i].toLowerCase()] = this.storage.retrieve(`translations_${String(languages[i])}`);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.ignoreSafetyNavigationModal) {
      return true;
    }

    this.openLeavePageModal('confirmationMessages.leaveUnsavedPageMessage');


    return this.leavePageModalRef.content.clickEvent.pipe(
      first(),
      map((res: ModalResponse) => res.okEvent)
    );
  }

  addLanguage(lang: string) {
    this.language.emit(lang.toLowerCase());
  }

  ready() {
    this.loading = false;
    setTimeout(() => {
      this.language.emit(this.getCurrentLanguage().toLowerCase());
    }, 1000);
  }

  render() {
    this.language.emit(this.getCurrentLanguage().toLowerCase());
  }
  protected loadAdditionalData(res: any) {
    this.submitted = true;
  }
}

import { Components } from "@formio/angular";
const EditGridComponent = Components.components.editgrid;

const EditRowState = {
  New: 'new',
  Editing: 'editing',
  Saved: 'saved',
  Viewing: 'viewing',
  Removed: 'removed',
  Draft: 'draft',
};
export default class EditGridComponentExtended extends EditGridComponent {

  init(){
    this.disabledRows = [];
    super.init();
  }

  static schema(...extend) {
    return EditGridComponent.schema({
      type: 'editgrid',
      label: 'Edit Grid',
      key: 'editGrid',
      clearOnHide: true,
      input: true,
      tree: true,
      removeRow: 'Cancel',
      defaultOpen: false,
      openWhenEmpty: false,
      modal: false,
      components: [],
      inlineEdit: false,
      templates: {
        header: EditGridComponent.defaultHeaderTemplate,
        row: EditGridComponentExtended.defaultRowTemplate,
        tableHeader: EditGridComponent.defaultTableHeaderTemplate,
        tableRow: EditGridComponentExtended.defaultTableRowTemplate,
        footer: '',
      },
      disabledRows: [],
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Edit Grid',
      icon: 'tasks',
      group: 'data',
      documentation: '/userguide/forms/data-components#edit-grid',
      weight: 30,
      schema: EditGridComponentExtended.schema(),
    };
  }


  static get defaultRowTemplate() {
    return `<div class="row">
          {% util.eachComponent(components, function(component) { %}
            {% if (displayValue(component)) { %}
              <div class="col-sm-2">
                {{ isVisibleInRow(component) ? t(getView(component, row[component.key])) : ''}}
              </div>
            {% } %}
          {% }) %}          
            <div class="col-sm-2">
              <div class="btn-group pull-right">
                <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ instance.disabledRows.includes(rowIndex) ? iconClass('eye') : iconClass('edit') }}"></i></button>
	                     {% if (!instance.options.readOnly && !instance.disabled && !instance.disabledRows.includes(rowIndex)) { %}
                  {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}
                    <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass('trash') }}"></i></button>
                  {% } %}
                {% } %}
              </div>
            </div>          
        </div>`;
  }

  static get defaultTableRowTemplate() {
    return `
          {% util.eachComponent(components, function(component) { %}
              {% if (!component.hasOwnProperty('tableView') || component.tableView) { %}
                <td class="editgrid-table-column">
                  {{ t(getView(component, row[component.key])) }}
                </td>
              {% } %}
            {% }) %}            
              <td class="editgrid-table-column">
                <div class="btn-group">
                  <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t('Edit row') }}"><i class="{{ instance.disabledRows.includes(rowIndex) ? iconClass('eye') : iconClass('edit') }}"></i></button>
                  {% if (!instance.options.readOnly && !instance.disabled && !instance.disabledRows.includes(rowIndex)) { %}
                    {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}
                    <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t('Remove row') }}"><i class="{{ iconClass('trash') }}"></i></button>
                    {% } %}
                  {% } %}
                </div>
              </td>`;
  }

  renderRow(row, rowIndex) {
    row.components.forEach(component => {
      const isDisabled = this.disabledRows.includes(rowIndex) ? true : component.component.disabled;
      component.disabled = isDisabled;

      if(component.type === 'form' && component.subForm) {
        component.subForm.disabled = isDisabled;
      }
    });
    return super.renderRow(row, rowIndex);
  }

  cancelRow(rowIndex) {
    const editRow = this.editRows[rowIndex];
    
    switch (editRow.state) {
      case EditRowState.New: {
        editRow.state = EditRowState.Removed;

        this.clearErrors(rowIndex);
        this.destroyComponents(rowIndex);
        if (this.inlineEditMode) {
          this.splice(rowIndex);
        }
        this.editRows.splice(rowIndex, 1);
        this.openWhenEmpty();
        break;
      }
      case EditRowState.Editing: {
        editRow.state = editRow.prevState;

        if (this.inlineEditMode) {
          this.dataValue[rowIndex] = editRow.backup;
        }
        editRow.data = editRow.backup;
        editRow.backup = null;
        this.restoreRowContext(editRow);
        this.clearErrors(rowIndex);
        break;
      }
      case EditRowState.Viewing: {
        editRow.state = editRow.prevState;
        this.restoreRowContext(editRow);
        this.clearErrors(rowIndex);
        break;
      }
    }

    this.emit('editGridCancelRow', {
      instance: this,
      component: this.component,
      editRow,
    });

    this.checkValidity(null, true);
    this.redraw();

    if (this.component.rowDrafts) {
      this.checkValidity(this.data, false);
    }
  }


}



// Register the component to the Formio.Components registry.
Components.addComponent("editgrid", EditGridComponentExtended);
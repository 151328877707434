import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Formio } from '@formio/angular';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { HelperService } from './services/helper.service/helper.service';
import { UserActivityService } from './services/user-activity/user-activity.service';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { IConfig } from './models/config.interface';
import { SignalRService } from './services/signalr.service';
import { MenuService } from './services/menu.service/menu.service';
import { LoadingStateService } from './services/loading-state.service/loading-state.service';
import { ContextService } from './services/context.service/context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  showSidebarData = true;
  showBreadcrumb = true;
  loading = false;
  private subscription: Subscription = new Subscription();

  constructor(protected readonly keycloak: KeycloakService,
    private translateService: TranslateService,
    private helper: HelperService,
    private userActivity: UserActivityService,
    private renderer: Renderer2,
    private signalRService: SignalRService,
    private contextService: ContextService,
    protected menuService: MenuService,
    protected loadingStateService: LoadingStateService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    document.body.classList.add(this.environment.env.projectCode);
  }

  public async ngOnInit(): Promise<void> {
    const faviconUrl = '/assets/images/icons/favicon/' + this.environment.env.projectCode + '-favicon.ico';
    document.getElementById('appFavicon').setAttribute('href', faviconUrl);
    this.setLanguage();
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    this.renderer.addClass(document.body, 'sidebar-active');
    this.renderer.removeClass(document.body, 'sidebar-inactive')
    if (this.isLoggedIn) {
      Formio.setUser(await this.keycloak.loadUserProfile());
      Formio.setToken(await this.keycloak.getToken());
      this.helper.loadPermissionsForResourceCreation();
      this.contextService.initContext();
    } else {
      Formio.setUser('');
      Formio.setToken('');
    }

    this.subscription.add(this.keycloak.keycloakEvents$.subscribe(event => {
      switch (event.type) {
        case KeycloakEventType.OnAuthRefreshSuccess: {
          void this.keycloak.getToken().then(token => { Formio.setToken(token); });
          this.helper.loadPermissionsForResourceCreation();
          break;
        }
        case KeycloakEventType.OnAuthRefreshError: {
          this.userActivity.failedToRefreshToken();
          break;
        }
        case KeycloakEventType.OnTokenExpired: {
          // TODO fix - session never ends
          void this.keycloak.updateToken(5).then((refreshed) => {
            if (refreshed) {
              void this.keycloak.getToken().then(token => { Formio.setToken(token); });
            }
          });
          break;
        }
        default:
          break;
      }
    })
  );

    this.subscription.add(this.signalRService.userRegistrationCompletedEventHandler.subscribe(() => {
      const refreshToken = this.keycloak.getKeycloakInstance().refreshTokenParsed;
      void this.keycloak.updateToken(refreshToken.exp - refreshToken.iat).then(() => {
        this.menuService.showMenuEvent.next(true);
        this.menuService.refreshMenuEvent.next(true);
        this.signalRService.resetConnection();
      });
    })
  );


  this.subscription.add(this.loadingStateService.activeLoadingState$.subscribe((value) => {
      this.loading = value;
    })
  );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setLanguage(): void {
    const language = this.helper.getCurrentSystemLanguage();

    if (language) {
      this.translateService.use(language);
    }
  }  
}

import { Component, Inject,  ViewChild, ElementRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from '../../../models/table-column.model';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { DefaultParams } from '../../../models/filter.model';
import { routesEnum } from '../../../enumerators/routesEnum';
import { formEnum } from '../../..//enumerators/Forms.enum';
import { HttpService } from '../../..//services/http-service/http.service';
import { IConfig } from '../../../models/config.interface';
import { APP_CONFIG } from '../../../utility/tokens';
import { HelperService } from '../../../services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from '../../../enumerators/classifier.enum';

@Component({
  selector: 'app-sms-provider-config',
  templateUrl: './sms-provider-config.component.html'
})
export class SMSProviderConfigComponent extends BaseListComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  searchData = new DefaultParams('Code asc');
  defaultSearchData = new DefaultParams('Code asc');
  
  listUrl = routesEnum.smsProvidersConfigList.url;
  formEnum = formEnum.smsProviderConfigForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Code', 'pages.smsProviderConfig.list.data.code'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService); 
    this.apiUrl = this.environment.api.smsProviderConfigs;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.IdGenerationConfig);
    this.classifiersLoading = false;
    this.actions = ['view', 'edit'];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }

  private onImport(): void{
    this.fileInput.nativeElement.click();
  }

  private onExport(): void {
    const tempSearchData = { ...{}, ...this.searchData };
    delete tempSearchData.SortBy;
    delete tempSearchData.PageSize;
    delete tempSearchData.Page;

    const param = this.httpService.getQueryParams(tempSearchData);
    const url = `${this.environment.api.smsProviderConfigs}/export?${param}`;

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.get(url, httpOptions).subscribe(res => {
      const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "smsProviderConfig.json";
      link.click();
      link.remove();

      this.classifiersLoading = false;
    },
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'application/json') {
        this.uploadFile(file);
      } else {
        this.toastrService.serverError('Only JSON files are allowed.');
      }
    }
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.post(`${this.environment.api.smsProviderConfigs}/import`, formData, httpOptions).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = "smsProviderConfig.json";
        link.click();
        link.remove();

        this.classifiersLoading = false;
        this.fileInput.nativeElement.value = "";
      },
      (err) => {
        this.toastrService.serverError(err);
        this.fileInput.nativeElement.value = "";
      }
    );
  }
}

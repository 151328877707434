<app-breadcrumb [isHeader]="true"></app-breadcrumb>
<div class="container-fluid c-max-width-755px">  
    <div class="w-100 padding-x-16 d-flex my-4">
        <button class="btn btn-primary btn-md bg bg-transparent icon-color-primary icon-center p-0 border-0" [routerLink]="'/myOffice'">
            <span class="fa-solid fa-arrow-left fa-2x cp-text-primary"></span>
        </button>
        <h4 class="my-0 mx-3" translate>pages.myOfficeRepresentative.list.title</h4>
    </div>    
    <app-base-filter 
      [btnAddNewTitle]="'pages.myOfficeRepresentative.list.addNewButton'"
      (clickCreate)="onCreate()">
    </app-base-filter>
    <div *ngIf="loading" class="container-fluid">
        <app-loader type="list"></app-loader>
    </div>
    <div *ngIf="!loading" class="table-holder">
        <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" [tableColumns]="tableColumns"
            [listActions]="actions" [classifiers]="classifierData" (edit)="onEdit($event)" (view)="onView($event)"
            (delete)="onDelete($event)" (sortChange)="onSortChange($event)">
        </app-data-table>
    </div>
    <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="totalItem"
        (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)"></app-pagging>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routesEnum } from 'projects/urp/src/app/enumerators/routesEnum';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';

@Component({
  selector: 'app-register-service',
  templateUrl: './register-service.component.html',
  styleUrls: ['./register-service.component.scss']
})

export class RegisterServiceComponent implements OnInit {
  registerServiceId = this.route.snapshot.queryParamMap.get('RegisterServiceId');
  registerServiceConfigurationId = this.route.snapshot.queryParamMap.get('RegisterServiceConfigurationId');
  registerServiceWorkflowConfigurationId = this.route.snapshot.queryParamMap.get('RegisterServiceWorkflowConfigurationId');
  registerServiceRegisterObjectLoadCleanUpConfigurationId = this.route.snapshot.queryParamMap.get('RegisterServiceRegisterObjectLoadCleanUpConfigurationId');
  readOnly = this.route.snapshot.queryParamMap.get('readOnly');

  //I dont know about this, leaving it tho, due to deadline
  navLinks = [{
      url: routesEnum.registerServiceGeneral.url, label: 'pages.registerService.form.generalInfo', queryParams: { 
        formCode: formEnum.registerServiceGeneralForm, 
        RegisterServiceId: this.registerServiceId, 
        RegisterServiceConfigurationId: this.registerServiceConfigurationId,
        RegisterServiceWorkflowConfigurationId: this.registerServiceWorkflowConfigurationId,
        RegisterServiceRegisterObjectLoadCleanUpConfigurationId: this.registerServiceRegisterObjectLoadCleanUpConfigurationId,
        readOnly: this.readOnly
      } },{
      url: routesEnum.registerServiceConfiguration.url, label: 'pages.registerService.form.configuration', queryParams: { 
        formCode: formEnum.registerServiceConfigurationForm,
        RegisterServiceId: this.registerServiceId, 
        RegisterServiceConfigurationId: this.registerServiceConfigurationId,
        RegisterServiceWorkflowConfigurationId: this.registerServiceWorkflowConfigurationId,
        RegisterServiceRegisterObjectLoadCleanUpConfigurationId: this.registerServiceRegisterObjectLoadCleanUpConfigurationId,
        readOnly: this.readOnly
     } },{
      url: routesEnum.registerServiceWorkflowConfiguration.url, label: 'pages.registerService.form.workflowConfiguration', queryParams: { 
        formCode: formEnum.registerServiceWorkflowConfigurationForm, 
        RegisterServiceId: this.registerServiceId, 
        RegisterServiceConfigurationId: this.registerServiceConfigurationId,
        RegisterServiceWorkflowConfigurationId: this.registerServiceWorkflowConfigurationId,
        RegisterServiceRegisterObjectLoadCleanUpConfigurationId: this.registerServiceRegisterObjectLoadCleanUpConfigurationId,
        readOnly: this.readOnly 
      } },{
      url: routesEnum.registerServiceRegistrationObjectLoadCleanUpConfiguration.url, label: 'pages.registerService.form.registrationObjectLoadCleanUpConfiguration', queryParams: { 
        formCode: formEnum.registerServiceObjectLoadAndConfigurationForm, 
        RegisterServiceId: this.registerServiceId, 
        RegisterServiceConfigurationId: this.registerServiceConfigurationId,
        RegisterServiceWorkflowConfigurationId: this.registerServiceWorkflowConfigurationId,
        RegisterServiceRegisterObjectLoadCleanUpConfigurationId: this.registerServiceRegisterObjectLoadCleanUpConfigurationId,
         readOnly: this.readOnly 
        } },
  ];
  
  constructor(protected route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      
      this.navLinks.forEach(navlink => {
        if (params.RegisterServiceId) {
          navlink.queryParams.RegisterServiceId = params.RegisterServiceId;
        }

        if (params.RegisterServiceConfigurationId) {
          navlink.queryParams.RegisterServiceConfigurationId = params.RegisterServiceConfigurationId;
        }

        if (params.RegisterServiceWorkflowConfigurationId) {
          navlink.queryParams.RegisterServiceWorkflowConfigurationId = params.RegisterServiceWorkflowConfigurationId;
        }

        if (params.RegisterServiceRegisterObjectLoadCleanUpConfigurationId) {
          navlink.queryParams.RegisterServiceRegisterObjectLoadCleanUpConfigurationId = params.RegisterServiceRegisterObjectLoadCleanUpConfigurationId;
        }
      });
    });
  }
}

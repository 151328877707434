<div *ngIf="loading" class="container-fluid">
    <app-loader type="list"></app-loader>
</div>

<div *ngIf="!loading">
    <app-breadcrumb [isHeader]="true"></app-breadcrumb>
    <div class="bg-secondary px-3">
        <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
            <app-filter-buttons [title]="'pages.representative.filter.title'" (clickClearFilter)="clearFilter()"
                (clickCloseFilter)="closeFilter()">
            </app-filter-buttons>
        </div>
        <div [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
            <div class="justify-content-center">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="" for="AccountName">{{'pages.representative.list.data.accountName' |
                                translate}}:</label>
                            <input id="AccountName" class="form-control" type="text" (keyup.enter)="onFilter()"
                                [(ngModel)]="searchData.AccountNameLike" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="" for="AccountIdentifier">{{'pages.representative.list.data.accountIdentifier'
                                |
                                translate}}:</label>
                            <input id="AccountIdentifier" class="form-control" type="text" (keyup.enter)="onFilter()"
                                [(ngModel)]="searchData.AccountIdentifierLike" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="" for="Username">{{'pages.representative.list.data.username' |
                                translate}}:</label>
                            <input id="Username" class="form-control" type="text" (keyup.enter)="onFilter()"
                                [(ngModel)]="searchData.UsernameLike" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label class="" for="userFullName">{{'pages.representative.list.data.userFullName' |
                                translate}}:</label>
                            <input id="userFullName" class="form-control" type="text" (keyup.enter)="onFilter()"
                                [(ngModel)]="searchData.UserFullNameLike" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group mt-3" [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
            <a translate class="btn btn-primary cp-font-weight-700" (click)="onFilter()">pages.common.list.searchButton
            </a>
        </div>
    </div>

    <input #fileInput type="file" (change)="onFileSelected($event)" accept=".json" style="display: none;">

    <ng-container>
        <div class="table-holder">
            <div class="grid-classifier-header">
                <div>
                    <h3 class="me-auto" translate>pages.representative.list.title</h3>
                </div>
                <div *ngIf="isFilterClosed">
                    <a class="btn btn-outline-primary open-filter-margin btn-right float-end" (click)="openFilter()"
                        translate>pages.common.list.filterButton
                        <i class="fa-solid fa-list"></i>
                    </a>
                </div>
            </div>
            <app-base-filter [btnAddNewTitle]="'pages.representative.list.addNewButton'" (clickCreate)="onCreate()"
                (clickBack)="onBack()">
            </app-base-filter>
            <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" [tableColumns]="tableColumns"
                [listActions]="actions" [classifiers]="classifierData" (sortChange)="onSortChange($event)"
                (edit)="onEdit($event)" (view)="onView($event)">
            </app-data-table>
        </div>
        <app-pagging [page]="searchData.Page" [pageSize]="searchData.PageSize" [total]="totalItem"
            (pageSizeChange)="onPageSizeChange($event)" (pageChange)="onPageChange($event)"></app-pagging>
    </ng-container>
</div>
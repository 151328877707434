import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { routesEnum } from '../../../enumerators/routesEnum';
import { formEnum } from '../../../enumerators/Forms.enum' 
import { DefaultParams } from '../../../models/filter.model'
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { HttpService } from '../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { Location } from '@angular/common';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-cron-job-configuration-list',
  templateUrl: './cron-job-configuration-list.component.html'
})
export class CronJobConfigurationListComponent extends BaseListComponent {

  // actions = ['edit', 'view', 'delete', 'list'];
  actions = [ 'view', 'delete', 'edit'];
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');
  
  listUrl = routesEnum.cronJobConfiguration.url;
  formEnum = formEnum.cronJobConfigurationForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Name', 'pages.cronJobConfiguration.list.data.name'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected readonly keycloak: KeycloakService,
              protected location: Location,
              protected httpService: HttpService,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedRoute, keycloak, location, httpService); 
    this.apiUrl = this.environment.api.cronJobConfigurations;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.CronJobConfiguration);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }
}

import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum'
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-register-list',
  templateUrl: './register-list.component.html'
})
export class RegisterListComponent extends BaseListComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  searchData = new DefaultParams('ShortName asc');
  defaultSearchData = new DefaultParams('ShortName asc');

  listUrl = routesEnum.registerList.url;
  formEnum = formEnum.registerForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('ShortName', 'pages.register.list.data.shortName'),
    new TableColumn('Name', 'pages.register.list.data.name'),
    new TableColumn('InstitutionId', 'pages.register.list.data.institution', 'guid', 'INSTITUTIONS'),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              protected route: ActivatedRoute,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) { 
    super(toastrService, modal, router, route, keycloak, location, httpService);
    this.apiUrl = this.environment.api.registers;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.Register);
    this.defaultSearchData['IsDeleted'] = false;
  }

  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.institution}/SelectSearch`).subscribe(res => {
        this.classifierData.INSTITUTIONS = res;
        this.classifiersLoading = false;
        this.loadList();
      }, (err) => {
        this.toastrService.serverError(err);
      });
  }

  private onImport(): void{
    this.fileInput.nativeElement.click();
  }

  private onExport(): void {
    const tempSearchData = { ...{}, ...this.searchData };
    delete tempSearchData.SortBy;
    delete tempSearchData.PageSize;
    delete tempSearchData.Page;

    const param = this.httpService.getQueryParams(tempSearchData);
    const url = `${this.environment.api.registers}/export?${param}`;

    const httpOptions = {
      responseType: 'blob'
    };

    this.httpService.get(url, httpOptions).subscribe(res => {
      const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "Register.zip";
      link.click();
      link.remove();

      this.classifiersLoading = false;
    },
      (err) => {
        this.toastrService.serverError(err);
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (file.type === 'application/x-zip-compressed') {
        this.uploadFile(file);
      } else {
        this.toastrService.serverError('Only zip files are allowed.');
      }
    }
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const httpOptions = {
      responseType: 'application/json'
    };

    this.httpService.post(`${this.environment.api.registers}/import`, formData, httpOptions).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(new Blob([res], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = "registerImportResult.json";
        link.click();
        link.remove();

        this.classifiersLoading = false;
        this.fileInput.nativeElement.value = "";
      },
      (err) => {
        this.toastrService.serverError(err);
        this.fileInput.nativeElement.value = "";
      }
    );
  }

}

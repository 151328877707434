import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject } from "rxjs";
import { IConfig } from "../../models/config.interface";
import { APP_CONFIG } from "../../utility/tokens";

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    constructor(
        protected readonly keycloak: KeycloakService,
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private readonly environment: IConfig) { }

    representatives = new BehaviorSubject<any>(null);
    representativeId = new BehaviorSubject<any>(null);

    representations = this.representatives.asObservable();
    representationId = this.representativeId.asObservable();

    initContext(): void {
        void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {
            this.httpClient.get(`${this.environment.api.umRepresentatives}?UserIds=${user['userId']}`).subscribe(res => {
                this.representatives.next(res);
                const id = res['Data'].filter(q => q.AccountName == q.UserFullName)[0].Id; // TODO need to crete window to select representation on login
                this.representativeId.next(id);
            });
        });
    }

    changeContext(id: Guid): void {
        this.representativeId.next(id);
    }
}
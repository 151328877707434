import _ from 'lodash';
import pdf from 'formiojs/PDF';
import WebformExtended from './WebformExtended';
import wizard from 'formiojs/Wizard';

export default class Displays {
  static displays = {
    pdf,
    WebformExtended,
    wizard,
  };

  static addDisplay(name, display) {
    Displays.displays[name] = display;
  }

  static addDisplays(displays) {
    Displays.displays = _.merge(Displays.displays, displays);
  }

  static getDisplay(name) {
    return Displays.displays[name];
  }

  static getDisplays() {
    return Displays.displays;
  }
}

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-skeleton',
  templateUrl: './list-skeleton.component.html'
})
export class ListSkeletonComponent {


}

import { Component, EventEmitter, Inject, Injector, ViewChild } from '@angular/core';
import { BaseFormComponent } from '../../../shared/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsService } from '../../../../services/forms.service/forms.service';
import { ToastrServiceExt } from '../../../../services/toastr.service/toastr.service';
import { HttpService } from '../../../../services/http-service/http.service';
import { HelperService } from '../../../../services/helper.service/helper.service';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from '../../../../services/local-storage.service/local-storage.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MenuService } from '../../../../services/menu.service/menu.service';
import { APP_CONFIG } from '../../../../utility/tokens';
import { IConfig } from '../../../../models/config.interface';
import { Location } from '@angular/common';

@Component({
  selector: 'app-register-service-general-info',
  templateUrl: '../../../shared/base-form/base-form.component.html',
  styleUrls: ['../../../shared/base-form/base-form.component.scss'],
})
export class RegisterServiceGeneralInfoComponent extends BaseFormComponent {
  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected formsService: FormsService,
    protected location: Location,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    protected helper: HelperService,
    protected readonly keycloak: KeycloakService,
    protected modal: BsModalService,
    protected storage: LocalStorageService,
    protected injector: Injector,
    protected translateService: TranslateService,
    protected menuService: MenuService,
    @Inject(APP_CONFIG) protected readonly environment: IConfig) {
    super(route, router, formsService, location, toastrService, httpService, helper, keycloak, modal, storage, translateService, injector, environment);
    this.form = { display: 'form', components: [] };
  }

  override ngOnInit(): void {
    this.subscription.add(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        if (this.formioComponent)
          this.addLanguage(this.getCurrentLanguage());
      })
    );

    this.activatedRoute.queryParams.subscribe(params => {
      this.submitted = false;
      const formId = params.FormId;
      const formCode = params.FormCode;
      const id = params.RegisterServiceId;
      this.params = params;
      this.readOnly = params.readOnly;
      if (formId) {
        this.getFormByIdAndLoad(formId, id);
      }
      else if (formCode) {
        this.formsService.getLastFormByCode(formCode).subscribe(res => {
          res = res[0];
          void this.setFormDataAndLoad(res, id);
        });
      }
      else if (id) {
        void this.loadDataBeforeForm(id);
      }
      else if (this.formCode) {
        this.formsService.getLastFormByCode(this.formCode).subscribe(res => {
          res = res[0];
          void this.setFormDataAndLoad(res, id);
        });
      }
    });
    if (this.activatedRoute.snapshot.data['apiController']) {
      this.apiController = this.injector.get<string>(this.activatedRoute.snapshot.data['apiController']);
    }

  }

  protected override setQueryParams(response: any, formCode?: string, formId?: string) {
    this.afterLoad(response.Data ? JSON.parse(response.Data) : response, formCode);
    this.setSubmission(response.Data ? JSON.parse(response.Data) : response, response.Id);

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: Object.assign({}, this.params, { 
        FormCode: formCode || this.formCode, 
        RegisterServiceId: response.ExpandoData.Id,
        RegisterServiceConfigurationId: response.Id,
        RegisterServiceWorkflowConfigurationId: response.RegisterServiceWorkflowConfigurationId,
        RegisterServiceRegisterObjectLoadCleanUpConfigurationId: response.RegisterServiceRegisterObjectLoadCleanUpConfigurationId,
        FormId: formId || this.formId}),
      replaceUrl: true
    });
  }
}

import { Guid } from "guid-typescript";

export class ProcessInstanceDto {
    Id: Guid;
    Name: string;
    ProcessDefinitionKey: string;

    constructor(Id: Guid, ProcessDefinitionKey: string) {
        this.Id = Id;
        this.ProcessDefinitionKey = ProcessDefinitionKey;
    }

}
